import React from "react";
import "react-toastify/dist/ReactToastify.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { ThemeContextProvider } from "./layout/themeContext";
import { SocketContext, SocketManager } from './context/Socket/SocketContext';
import Routes from "./routes";

const queryClient = new QueryClient();

const App = () => {
  return (
    <ThemeContextProvider>
      <QueryClientProvider client={queryClient}>
        <SocketContext.Provider value={SocketManager}>
          <Routes />
        </SocketContext.Provider>
      </QueryClientProvider>
    </ThemeContextProvider>
  );
};

export default App;
