import React, { useState, useEffect, useContext, useCallback } from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { makeStyles } from '@mui/styles';
import styled from 'styled-components';
import api from '../../services/api';
import { AuthContext } from '../../context/Auth/AuthContext';
import { toast } from 'react-toastify';
import { i18n } from '../../translate/i18n';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    padding: theme.spacing(1),
    height: 'calc(100vh - 100px)',
  },
  button: {
    background: '#10a110',
    border: 'none',
    padding: '10px',
    color: 'white',
    fontWeight: 'bold',
    borderRadius: '5px',
    cursor: 'pointer',
    '&:hover': {
      background: '#0d800d',
    },
  },
}));

const Container = styled.div`
  display: flex;
  gap: 20px;
  align-items: flex-start;
  height: 100%;
  padding-bottom: 16px;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
`;

const Column = styled.div`
  min-width: 300px;
  background: ${props => props.isOpen ? '#E3E3E2' : props.color || '#f4f5f7'};
  border-radius: 8px;
  padding: 16px;
  color: ${props => !props.isOpen && props.color ? 'white' : '#172b4d'};
  height: fit-content;
  display: flex;
  flex-direction: column;

  header {
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: inherit;
    padding: 8px;
    flex-shrink: 0;
    border-radius: 6px;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      bottom: -8px;
      left: 0;
      right: 0;
      height: 2px;
      background: ${props => props.isOpen ? 'rgba(0, 0, 0, 0.1)' : 'rgba(255, 255, 255, 0.2)'};
      border-radius: 2px;
    }

    .header-content {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    h2 {
      font-size: 15px;
      font-weight: 600;
      margin: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 200px;
    }

    span {
      background: ${props => props.isOpen ? 'rgba(0, 0, 0, 0.08)' : 'rgba(255, 255, 255, 0.2)'};
      padding: 4px 8px;
      border-radius: 12px;
      font-size: 13px;
      font-weight: 500;
      min-width: 24px;
      text-align: center;
      box-shadow: ${props => props.isOpen ? '0 1px 2px rgba(0, 0, 0, 0.05)' : 'none'};
    }
  }
`;

const ColumnContent = styled.div`
  overflow-y: auto;
  min-height: 0;
  flex: 1;
  margin: 0 -8px;
  padding: 0 8px;
  
  /* Estilização da scrollbar */
  ::-webkit-scrollbar {
    width: 8px;
  }
  
  ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }
  
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
  }
`;

const Card = styled.div`
  background: white;
  border-radius: 6px;
  padding: 16px;
  margin-bottom: 8px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  color: #172b4d;
  user-select: none;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #f8f9fa;
  }

  h3 {
    font-size: 14px;
    margin: 0 0 8px;
  }

  p {
    font-size: 13px;
    color: #5e6c84;
    margin: 0 0 8px;
  }
`;

const Kanban = () => {
  const classes = useStyles();
  const history = useHistory();
  const [columns, setColumns] = useState({});
  const [tags, setTags] = useState([]);
  const { user } = useContext(AuthContext);
  const { profile } = user;
  const queueIds = user.queues.map(queue => queue.UserQueue.queueId);

  const fetchTags = useCallback(async () => {
    try {
      const response = await api.get('/tags/kanban');
      const fetchedTags = response.data.lista ?? [];
      setTags(fetchedTags);
    } catch (error) {
      console.error('Erro ao buscar tags:', error);
      toast.error(i18n.t('kanban.toasts.fetchTagsError'));
    }
  }, []);

  const fetchTickets = useCallback(async () => {
    try {
      const { data } = await api.get('/ticket/kanban', {
        params: {
          queueIds: JSON.stringify(queueIds),
          showAll: profile === 'admin',
        },
      });

      const newColumns = {
        'sem-tag': {
          id: 'sem-tag',
          title: i18n.t('kanban.open'),
          tickets: data.tickets.filter(ticket => ticket.tags.length === 0),
        },
      };

      if (tags.length > 0) {
        tags.forEach(tag => {
          newColumns[tag.id] = {
            id: tag.id.toString(),
            title: tag.name,
            color: tag.color,
            tickets: data.tickets.filter(ticket => 
              ticket.tags.some(ticketTag => ticketTag.id === tag.id)
            ),
          };
        });
      }

      setColumns(newColumns);
    } catch (error) {
      console.error('Erro ao buscar tickets:', error);
      toast.error(i18n.t('kanban.toasts.fetchError'));
    }
  }, [profile, queueIds, tags]);

  useEffect(() => {
    fetchTags();
  }, [fetchTags]);

  useEffect(() => {
    fetchTickets();
  }, [tags, fetchTickets]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    return {
      [droppableSource.droppableId]: sourceClone,
      [droppableDestination.droppableId]: destClone,
    };
  };

  const handleDragEnd = async (result) => {
    const { source, destination, draggableId } = result;
  
    if (!destination) return;
  
    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }
  
    const startColumn = columns[source.droppableId];
    const finishColumn = columns[destination.droppableId];
  
    const newColumns = { ...columns };
  
    if (source.droppableId === destination.droppableId) {
      const reorderedTickets = reorder(
        startColumn.tickets,
        source.index,
        destination.index
      );
  
      newColumns[source.droppableId] = {
        ...startColumn,
        tickets: reorderedTickets,
      };
    } else {
      const movedItems = move(
        startColumn.tickets,
        finishColumn.tickets,
        source,
        destination
      );
  
      newColumns[source.droppableId] = {
        ...startColumn,
        tickets: movedItems[source.droppableId],
      };
  
      newColumns[destination.droppableId] = {
        ...finishColumn,
        tickets: movedItems[destination.droppableId],
      };
    }
  
    try {
      // Atualiza no backend antes de atualizar o estado local
      if (source.droppableId !== 'sem-tag') {
        await api.delete(`/ticket-tags/${draggableId}`);
      }
  
      if (destination.droppableId !== 'sem-tag') {
        await api.put(`/ticket-tags/${draggableId}/${destination.droppableId}`);
      }
  
      setColumns(newColumns);
      toast.success(i18n.t('kanban.toasts.updated'));
    } catch (error) {
      console.error('Erro ao mover ticket:', error);
      toast.error(i18n.t('kanban.toasts.moveError'));
      await fetchTickets(); // Caso haja erro, refaz a busca dos tickets
    }
  };

  const handleCardClick = (uuid) => {
    history.push(`/tickets/${uuid}`);
  };

  return (
    <div className={classes.root}>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Container>
          {/* Renderiza primeiro a coluna "Em aberto" */}
          {columns['sem-tag'] && (
            <Droppable key="sem-tag" droppableId="sem-tag">
              {(provided, snapshot) => (
                <Column
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  isOpen={true}
                  style={{
                    backgroundColor: snapshot.isDraggingOver ? '#E8E8E7' : '#E3E3E2'
                  }}
                >
                  <header>
                    <div className="header-content">
                      <h2>{columns['sem-tag'].title}</h2>
                      <span>{columns['sem-tag'].tickets.length}</span>
                    </div>
                  </header>
                  <ColumnContent>
                    {columns['sem-tag'].tickets.map((ticket, index) => (
                      <Draggable
                        key={ticket.id}
                        draggableId={ticket.id.toString()}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <Card
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              ...provided.draggableProps.style,
                              transform: snapshot.isDragging
                                ? provided.draggableProps.style.transform
                                : 'translate(0, 0)',
                              transition: snapshot.isDragging
                                ? provided.draggableProps.style.transition
                                : 'transform 0.2s ease-in-out'
                            }}
                          >
                            <h3>Ticket nº {ticket.id}</h3>
                            <p>{ticket.contact.name}</p>
                            <p>{ticket.contact.number}</p>
                            <p>{ticket.lastMessage}</p>
                            <button
                              className={classes.button}
                              onClick={() => handleCardClick(ticket.uuid)}
                            >
                              {i18n.t('kanban.seeTicket')}
                            </button>
                          </Card>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </ColumnContent>
                </Column>
              )}
            </Droppable>
          )}
          {/* Renderiza as demais colunas */}
          {Object.values(columns)
            .filter(column => column.id !== 'sem-tag')
            .map(column => (
            <Droppable key={column.id} droppableId={column.id}>
              {(provided, snapshot) => (
                <Column
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  color={column.color}
                  isOpen={false}
                  style={{
                    backgroundColor: snapshot.isDraggingOver 
                      ? `${column.color}dd` 
                      : column.color
                  }}
                >
                  <header>
                    <div className="header-content">
                      <h2>{column.title}</h2>
                      <span>{column.tickets.length}</span>
                    </div>
                  </header>
                  <ColumnContent>
                    {column.tickets.map((ticket, index) => (
                      <Draggable
                        key={ticket.id}
                        draggableId={ticket.id.toString()}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <Card
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              ...provided.draggableProps.style,
                              transform: snapshot.isDragging
                                ? provided.draggableProps.style.transform
                                : 'translate(0, 0)',
                              transition: snapshot.isDragging
                                ? provided.draggableProps.style.transition
                                : 'transform 0.2s ease-in-out'
                            }}
                          >
                            <h3>Ticket nº {ticket.id}</h3>
                            <p>{ticket.contact.name}</p>
                            <p>{ticket.contact.number}</p>
                            <p>{ticket.lastMessage}</p>
                            <button
                              className={classes.button}
                              onClick={() => handleCardClick(ticket.uuid)}
                            >
                              {i18n.t('kanban.seeTicket')}
                            </button>
                          </Card>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </ColumnContent>
                </Column>
              )}
            </Droppable>
          ))}
        </Container>
      </DragDropContext>
    </div>
  );
};

export default Kanban;
