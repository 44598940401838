const rules = {
	user: {
		static: [
			"dashboard:view",
			"tickets:view",
			"contacts:view",
			"helps:view",
		],
	},

	admin: {
		static: [
			"dashboard:view",
			"drawer-admin-items:view",
			"tickets-manager:showall",
			"user-modal:editProfile",
			"user-modal:editQueues",
			"ticket-options:deleteTicket",
			"contacts-page:deleteContact",
			"connections-page:actionButtons",
			"connections-page:addConnection",
			"connections-page:editOrDeleteConnection",
			"tickets:view",
			"contacts:view",
			"helps:view",
			"quickAnswers:view",
			"schedules:view",
			"tags:view",
			"kanban:view",
			"users:view",
			"queues:view",
			"campaigns:view",
			"announcements:view",
			"chats:view",
			"integrations:view",
			"api:view",
			"settings:view",
			"connections:view",
		],
	},
};

export default rules;
