// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* cor quando passa o mouse por cima */
.MuiButtonBase-root.buttonHover:hover {
    background: rgb(36, 36, 36)
  }
  
  /* cor enquanto não passa o mouse */
  .MuiButtonBase-root.buttonHover {
    background: rgb(71, 71, 71)
  }`, "",{"version":3,"sources":["webpack://./src/pages/Dashboard/button.css"],"names":[],"mappings":"AAAA,sCAAsC;AACtC;IACI;EACF;;EAEA,mCAAmC;EACnC;IACE;EACF","sourcesContent":["/* cor quando passa o mouse por cima */\n.MuiButtonBase-root.buttonHover:hover {\n    background: rgb(36, 36, 36)\n  }\n  \n  /* cor enquanto não passa o mouse */\n  .MuiButtonBase-root.buttonHover {\n    background: rgb(71, 71, 71)\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
