import React from 'react';
import { 
  DialogTitle, 
  Typography, 
  IconButton, 
  Box,
  useTheme
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import PropTypes from 'prop-types';

/**
 * Componente de cabeçalho para modais com título e botão de fechar
 * 
 * @param {Object} props - Propriedades do componente
 * @param {string} props.title - Título do modal
 * @param {function} props.onClose - Função a ser chamada quando o botão de fechar for clicado
 * @param {React.ReactNode} props.icon - Ícone opcional para exibir antes do título
 * @param {Object} props.sx - Estilos adicionais para o DialogTitle
 * @returns {React.ReactElement} Componente ModalHeader
 */
const ModalHeader = ({ title, onClose, icon, sx = {} }) => {
  const theme = useTheme();

  return (
    <DialogTitle 
      sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        padding: theme.spacing(1, 2),
        ...sx
      }}
    >
      <Box display="flex" alignItems="center">
        {icon && (
          <Box mr={1} display="flex" alignItems="center">
            {icon}
          </Box>
        )}
        <Typography variant="h6">
          {title}
        </Typography>
      </Box>
      {onClose && (
        <IconButton 
          aria-label="close" 
          onClick={onClose} 
          size="small"
          sx={{ color: theme.palette.primary.contrastText }}
        >
          <CloseIcon />
        </IconButton>
      )}
    </DialogTitle>
  );
};

ModalHeader.propTypes = {
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  icon: PropTypes.node,
  sx: PropTypes.object,
};

export default ModalHeader; 