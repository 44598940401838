import React from 'react';
import { Box } from '@mui/material';

const CircleFlag = ({ countryCode, ...props }) => (
  <Box
    component="img"
    src={`https://hatscripts.github.io/circle-flags/flags/${countryCode}.svg`}
    alt={`${countryCode} flag`}
    sx={{
      width: 24,
      height: 24,
      objectFit: 'cover',
      display: 'inline-block',
      verticalAlign: 'middle',
      ...props.sx
    }}
    {...props}
  />
);

export const BrazilFlag = (props) => (
  <CircleFlag countryCode="br" {...props} />
);

export const USAFlag = (props) => (
  <CircleFlag countryCode="us" {...props} />
);

export const SpainFlag = (props) => (
  <CircleFlag countryCode="es" {...props} />
); 