import React, { useState } from 'react';
import { Box, CssBaseline } from '@mui/material';
import { ThemeContextProvider } from './themeContext';
import Header from './components/Header';
import Sidebar from './components/Sidebar';

const drawerWidth = 0;

const Layout = ({ children }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleDrawerMinimize = () => {
    setIsMinimized(!isMinimized);
  };

  return (
    <ThemeContextProvider>
      <Box sx={{ display: 'flex', minHeight: '100vh' }}>
        <CssBaseline />
        
        {/* Header */}
        <Header 
          onDrawerToggle={handleDrawerMinimize} 
          onMobileDrawerToggle={handleDrawerToggle}
          isMinimized={isMinimized}
        />

        {/* Sidebar */}
        <Sidebar 
          mobileOpen={mobileOpen} 
          onDrawerToggle={handleDrawerToggle}
          isMinimized={isMinimized}
        />

        {/* Main content */}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            width: { 
              xs: '100%',
              sm: `calc(100% - ${isMinimized ? '57px' : drawerWidth}px)`
            },
            ml: { 
              xs: 0,
              sm: isMinimized ? '57px' : `${drawerWidth}px`
            },
            mt: '64px',
            transition: theme => theme.transitions.create(['width', 'margin'], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
          }}
        >
          {children}
        </Box>
      </Box>
    </ThemeContextProvider>
  );
};

export default Layout; 