import { styled } from "@mui/material/styles";
import React from "react";

const TagDiv = styled('div')(({ theme, color }) => ({
    padding: "1px 5px",
    borderRadius: "3px",
    fontSize: "0.8em",
    fontWeight: "bold",
    color: "#FFF",
    marginRight: "5px",
    whiteSpace: "nowrap",
    backgroundColor: color,
    marginTop: "2px"
}));

const ContactTag = ({ tag }) => {
    return (
        <TagDiv color={tag.color}>
            {tag.name.toUpperCase()}
        </TagDiv>
    )
}

export default ContactTag;