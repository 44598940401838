import React from "react";
import { useMediaQuery, useTheme } from '@mui/material';

import Tickets from "../TicketsCustom"
import TicketAdvanced from "../TicketsAdvanced";

const TicketResponsiveContainer = ({ children }) => {
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'));
    if (isMd) {
        return <Tickets />;    
    }
    return <TicketAdvanced />
}

export default TicketResponsiveContainer;