import React from 'react';
import {
  Drawer,
  Box,
  Divider,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import MenuItems from './MenuItems';
import logo from '../../assets/logo.png';
import typebotIcon from '../../assets/typebot-ico.png';

const drawerWidth = 240;
const miniDrawerWidth = 57;

const Sidebar = ({ mobileOpen, onDrawerToggle, isMinimized }) => {
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  const getDrawerWidth = () => {
    if (!isSmUp) return drawerWidth;
    if (isMdUp && !isMinimized) return drawerWidth;
    return miniDrawerWidth;
  };

  const shouldShowMinimizedLogo = () => {
    if (!isSmUp) return false;
    return isMinimized || !isMdUp;
  };

  const shouldMinimizeMenu = () => {
    if (!isSmUp) return false;
    return isMinimized || !isMdUp;
  };

  const shouldShowToggle = () => {
    if (!isSmUp) return true;
    if (isMdUp) return true;
    return false;
  };

  const drawer = (
    <>
      <Box
        sx={{
          height: 64,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: theme.palette.mode === 'light' ? 'white' : theme.palette.background.default,
          borderBottom: `1px solid ${theme.palette.divider}`,
        }}
      >
        <Box
          component="img"
          src={shouldShowMinimizedLogo() ? typebotIcon : logo}
          alt="Logo"
          sx={{
            height: shouldShowMinimizedLogo() ? 35 : 40,
            width: 'auto',
            transition: theme.transitions.create(['height'], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
          }}
        />
      </Box>
      <Box sx={{ 
        overflow: 'auto',
        height: 'calc(100% - 64px)',
        '&::-webkit-scrollbar': {
          width: '6px',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(0, 0, 0, 0.2)',
          borderRadius: '3px',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
          }
        },
        '&::-webkit-scrollbar-track': {
          backgroundColor: 'transparent',
        }
      }}>
        <MenuItems isMinimized={shouldMinimizeMenu()} />
      </Box>
    </>
  );

  return (
    <Box
      component="nav"
      sx={{ 
        width: { 
          xs: mobileOpen ? drawerWidth : 0,
          sm: getDrawerWidth()
        }, 
        flexShrink: { sm: 0 } 
      }}
    >
      {/* Mobile drawer */}
      {!isSmUp && (
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={onDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
              top: 0,
            },
          }}
        >
          {drawer}
        </Drawer>
      )}
      
      {/* Desktop/Tablet drawer */}
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: getDrawerWidth(),
            overflowX: 'hidden',
            top: 0,
            height: '100%',
            transition: theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
          },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

export default Sidebar; 