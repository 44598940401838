import React, { useContext, useState } from 'react';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Collapse,
  Tooltip,
  Divider,
  Typography,
  useTheme,
} from '@mui/material';

import { Icon } from '@iconify/react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

import { useHistory, useLocation } from 'react-router-dom';
import { AuthContext } from '../../context/Auth/AuthContext';
import { Can } from '../../components/Can';
import { i18n } from '../../translate/i18n';

const MenuItems = ({ isMinimized }) => {
  const history = useHistory();
  const location = useLocation();
  const { user } = useContext(AuthContext);
  const [openSubmenus, setOpenSubmenus] = useState({});
  const theme = useTheme();

  const isItemActive = (itemPath) => {
    if (!itemPath) return false;
    return location.pathname === itemPath || location.pathname.startsWith(`${itemPath}/`);
  };

  const handleSubmenuClick = (menuId) => {
    if (isMinimized) return;
    setOpenSubmenus(prev => ({
      ...prev,
      [menuId]: !prev[menuId]
    }));
  };

  const renderMenuItem = (item) => {
    const active = isItemActive(item.path);
    
    const listItemButton = (
      <ListItemButton
        onClick={() => item.path ? history.push(item.path) : handleSubmenuClick(item.id)}
        sx={{
          ...(isMinimized ? { justifyContent: 'center', px: 2.5 } : {}),
          ...(active && {
            backgroundColor: `${theme.palette.primary.main}15`,
            borderLeft: `3px solid ${theme.palette.primary.main}`,
            '&:hover': {
              backgroundColor: `${theme.palette.primary.main}25`,
            }
          })
        }}
      >
        <ListItemIcon 
          sx={{
            ...(isMinimized ? { minWidth: 0, justifyContent: 'center' } : { minWidth: '35px' }),
            ...(active && {
              color: theme.palette.primary.main
            }),
            '& svg': {
              width: '22px',
              height: '22px'
            }
          }}
        >
          {item.icon}
        </ListItemIcon>
        {!isMinimized ? (
          <>
            <ListItemText 
              primary={item.text} 
              sx={active ? { 
                '& .MuiListItemText-primary': { 
                  color: theme.palette.primary.main,
                  fontWeight: 'bold'
                }
              } : {}}
            />
            {item.items && (openSubmenus[item.id] ? <ExpandLess /> : <ExpandMore />)}
          </>
        ) : null}
      </ListItemButton>
    );

    if (item.items) {
      return (
        <Can
          key={item.text}
          role={user?.profile}
          perform={item.permission}
          yes={() => (
            <>
              <ListItem disablePadding>
                {isMinimized ? (
                  <Tooltip title={item.text} placement="right">
                    {listItemButton}
                  </Tooltip>
                ) : (
                  listItemButton
                )}
              </ListItem>
              {!isMinimized && (
                <Collapse in={openSubmenus[item.id]} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {item.items.map((subItem) => {
                      const subItemActive = isItemActive(subItem.path);
                      return (
                        <ListItem key={subItem.text} disablePadding>
                          <ListItemButton
                            sx={{
                              pl: 4,
                              ...(subItemActive && {
                                backgroundColor: `${theme.palette.primary.main}15`,
                                borderRight: `3px solid ${theme.palette.primary.main}`,
                                '&:hover': {
                                  backgroundColor: `${theme.palette.primary.main}25`,
                                }
                              })
                            }}
                            onClick={() => history.push(subItem.path)}
                          >
                            <ListItemIcon
                              sx={{
                                ...(subItemActive ? {
                                  color: theme.palette.primary.main
                                } : {}),
                                '& svg': {
                                  width: '26px',
                                  height: '26px'
                                }
                              }}
                            >
                              {subItem.icon}
                            </ListItemIcon>
                            <ListItemText 
                              primary={subItem.text}
                              sx={subItemActive ? { 
                                '& .MuiListItemText-primary': { 
                                  color: theme.palette.primary.main,
                                  fontWeight: 'bold'
                                }
                              } : {}}
                            />
                          </ListItemButton>
                        </ListItem>
                      );
                    })}
                  </List>
                </Collapse>
              )}
            </>
          )}
          no={() => null}
        />
      );
    }

    return (
      <Can
        key={item.text}
        role={user?.profile}
        perform={item.permission}
        yes={() => (
          <ListItem disablePadding>
            {isMinimized ? (
              <Tooltip title={item.text} placement="right">
                {listItemButton}
              </Tooltip>
            ) : (
              listItemButton
            )}
          </ListItem>
        )}
        no={() => null}
      />
    );
  };

  // Definição dos itens do menu com agrupamentos lógicos
  const menuGroups = [
    // Dashboard no topo (apenas admin)
    {
      adminOnly: true,
      items: [
        {
          text: i18n.t("mainDrawer.listItems.dashboard"),
          icon: <Icon icon="lucide:layout-dashboard" />,
          path: '/',
          permission: 'dashboard:view',
        },
      ]
    },
    // Grupo Operacional - Funções do dia a dia
    {
      title: "Operacional",
      items: [
        {
          text: i18n.t("mainDrawer.listItems.tickets"),
          icon: <Icon icon="tdesign:service" />,
          path: '/tickets',
          permission: 'tickets:view',
        },
        {
          text: i18n.t("mainDrawer.listItems.contacts"),
          icon: <Icon icon="lucide:contact-round" />,
          path: '/contacts',
          permission: 'tickets:view',
        },
        {
          text: i18n.t("mainDrawer.listItems.quickMessages"),
          icon: <Icon icon="lucide:rabbit" />,
          path: '/quick-messages',
          permission: 'tickets:view',
        },
        {
          text: i18n.t("mainDrawer.listItems.schedules"),
          icon: <Icon icon="lucide:calendar-clock" />,
          path: '/schedules',
          permission: 'tickets:view',
        },
        {
          text: i18n.t("mainDrawer.listItems.tags"),
          icon: <Icon icon="lucide:tags" />,
          path: '/tags',
          permission: 'tickets:view',
        },
        {
          text: "Kanban",
          icon: <Icon icon="lucide:square-kanban" />,
          path: '/kanban',
          permission: 'tickets:view',
        },
        {
          text: i18n.t("mainDrawer.listItems.chats"),
          icon: <Icon icon="bx:chat" />,
          path: '/chats',
          permission: 'tickets:view',
        },
      ]
    },
    // Grupo de Gestão - Funções administrativas
    {
      title: "Gestão",
      adminOnly: true,
      items: [
        {
          text: i18n.t("mainDrawer.listItems.connections"),
          icon: <Icon icon="gravity-ui:plug-connection" />,
          path: '/connections',
          permission: 'connections:view',
        },
        {
          text: i18n.t("mainDrawer.listItems.users"),
          icon: <Icon icon="lucide:users" />,
          path: '/users',
          permission: 'users:view',
        },
        {
          text: i18n.t("mainDrawer.listItems.queues"),
          icon: <Icon icon="ri:robot-2-line" />,
          path: '/queues',
          permission: 'queues:view',
        },
        {
          id: 'campaigns',
          text: i18n.t("mainDrawer.listItems.campaigns"),
          icon: <Icon icon="lucide:megaphone" />,
          permission: 'campaigns:view',
          items: [
            {
              text: i18n.t("mainDrawer.listItems.campaigns"),
              icon: <Icon icon="mdi:event" />,
              path: '/campaigns',
              permission: 'campaigns:view',
            },
            {
              text: i18n.t("mainDrawer.listItems.campaignsConfig"),
              icon: <Icon icon="mdi:event-available" />,
              path: '/campaigns-config',
              permission: 'campaigns:view',
            },
          ],
        },
        {
          id: 'flows',
          text: i18n.t("mainDrawer.listItems.flows"),
          icon: <Icon icon="lucide:workflow" />,
          permission: 'campaigns:view',
          items: [
            {
              text: "Campanha",
              icon: <Icon icon="mdi:event-available" />,
              path: '/phrase-lists',
              permission: 'campaigns:view',
            },
            {
              text: "Conversa",
              icon: <Icon icon="mdi:forum" />,
              path: '/flowbuilders',
              permission: 'campaigns:view',
            },
          ],
        },
        {
          text: i18n.t("mainDrawer.listItems.annoucements"),
          icon: <Icon icon="lucide:bell" />,
          path: '/announcements',
          permission: 'announcements:view',
        },
      ]
    },
    // Grupo de Sistema - Configurações e Suporte
    {
      title: "Sistema",
      items: [
        {
          text: i18n.t("mainDrawer.listItems.queueIntegration"),
          icon: <Icon icon="lucide:square-dashed-bottom-code" />,
          path: '/queue-integration',
          permission: 'integrations:view',
        },
        {
          text: i18n.t("mainDrawer.listItems.messagesAPI"),
          icon: <Icon icon="lucide:file-json" />,
          path: '/messages-api',
          permission: 'api:view',
        },
        {
          text: i18n.t("mainDrawer.listItems.helps"),
          icon: <Icon icon="lucide:circle-help" />,
          path: '/helps',
          permission: 'helps:view',
        },
        {
          text: i18n.t("mainDrawer.listItems.settings"),
          icon: <Icon icon="mdi:cog" />,
          path: '/settings',
          permission: 'settings:view',
        },
      ]
    },
  ];

  return (
    <List>
      {menuGroups.map((group, index) => {
        // Se o grupo for apenas para admin e o usuário não for admin, não renderiza
        if (group.adminOnly && user?.profile !== 'admin') {
          return null;
        }

        return (
          <React.Fragment key={index}>
            {index > 0 && <Divider sx={{ my: 1 }} />}
            {!isMinimized && group.title && (
              <Typography
                variant="caption"
                color="textSecondary"
                sx={{
                  px: 3,
                  mt: 2,
                  mb: 1,
                  display: 'block',
                  textTransform: 'uppercase',
                  fontWeight: 'medium',
                  fontSize: '0.75rem',
                  letterSpacing: '0.08em',
                }}
              >
                {group.title}
              </Typography>
            )}
            {group.items.map(item => renderMenuItem(item))}
          </React.Fragment>
        );
      })}
    </List>
  );
};

export default MenuItems; 