import React, { useEffect, useState, useContext } from "react";
import { QRCodeSVG } from "qrcode.react";
import toastError from "../../errors/toastError";

import { 
  Dialog, 
  DialogContent, 
  Paper, 
  Typography, 
  useTheme, 
  Box, 
  Grid,
  styled
} from "@mui/material";

import {
  MoreVert as MoreVertIcon,
  Settings as SettingsIcon,
  PhoneAndroid as PhoneAndroidIcon,
  QrCode as QrCodeIcon
} from "@mui/icons-material";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import { SocketContext } from "../../context/Socket/SocketContext";
import ModalHeader from "../ModalHeader";

// Componentes estilizados
const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(3),
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
}));

const StepContainer = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const Step = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "flex-start",
  marginBottom: theme.spacing(1.5),
}));

const StepNumber = styled("span")(({ theme }) => ({
  fontWeight: "bold",
  marginRight: theme.spacing(1),
  color: theme.palette.primary.main,
}));

const StepText = styled(Typography)(({ theme }) => ({
  fontSize: "0.95rem",
  lineHeight: 1.5,
}));

const QrCodeContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.default,
  borderRadius: theme.shape.borderRadius,
  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
  height: "100%",
}));

const QrCodeBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: "#fff",
  borderRadius: theme.shape.borderRadius,
  boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)",
}));

const WaitingBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const QrcodeModal = ({ open, onClose, whatsAppId }) => {
  const [qrCode, setQrCode] = useState("");
  const theme = useTheme();

  const socketManager = useContext(SocketContext);

  useEffect(() => {
    const fetchSession = async () => {
      if (!whatsAppId) return;

      try {
        const { data } = await api.get(`/whatsapp/${whatsAppId}`);
        setQrCode(data.qrcode);
      } catch (err) {
        toastError(err);
      }
    };
    fetchSession();
  }, [whatsAppId]);

  useEffect(() => {
    if (!whatsAppId) return;
    const companyId = localStorage.getItem("companyId");
    const socket = socketManager.getSocket(companyId);

    socket.on(`company-${companyId}-whatsappSession`, (data) => {
      if (data.action === "update" && data.session.id === whatsAppId) {
        setQrCode(data.session.qrcode);
      }

      if (data.action === "update" && data.session.qrcode === "") {
        onClose();
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [whatsAppId, onClose, socketManager]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" scroll="paper">
      <ModalHeader
        title={i18n.t("qrCodeModal.title")}
        onClose={onClose}
        icon={<QrCodeIcon />}
      />
      <StyledDialogContent>
        <StyledPaper elevation={0}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={7}>
              <StepContainer>
                <Step>
                  <StepNumber>1.</StepNumber>
                  <StepText variant="body1">
                    {i18n.t("qrCodeModal.steps.one")}
                  </StepText>
                </Step>
                
                <Step>
                  <StepNumber>2.</StepNumber>
                  <StepText variant="body1">
                    {i18n.t("qrCodeModal.steps.two.partOne")} 
                    <MoreVertIcon sx={{ 
                      fontSize: "1.1rem", 
                      verticalAlign: "middle", 
                      mx: 0.5, 
                      color: "text.secondary" 
                    }} /> 
                    {i18n.t("qrCodeModal.steps.two.partTwo")} 
                    <SettingsIcon sx={{ 
                      fontSize: "1.1rem", 
                      verticalAlign: "middle", 
                      mx: 0.5, 
                      color: "text.secondary" 
                    }} /> 
                    {i18n.t("qrCodeModal.steps.two.partThree")}
                  </StepText>
                </Step>
                
                <Step>
                  <StepNumber>3.</StepNumber>
                  <StepText variant="body1">
                    {i18n.t("qrCodeModal.steps.three")}
                  </StepText>
                </Step>
                
                <Step>
                  <StepNumber>4.</StepNumber>
                  <StepText variant="body1">
                    {i18n.t("qrCodeModal.steps.four")}
                  </StepText>
                </Step>
              </StepContainer>
            </Grid>
            
            <Grid item xs={12} md={5}>
              <QrCodeContainer>
                {qrCode ? (
                  <QrCodeBox>
                    <QRCodeSVG value={qrCode} size={220} />
                  </QrCodeBox>
                ) : (
                  <WaitingBox>
                    <PhoneAndroidIcon sx={{ fontSize: 48, opacity: 0.5, mb: 2 }} />
                    <Typography variant="body1">
                      {i18n.t("qrCodeModal.waiting")}
                    </Typography>
                  </WaitingBox>
                )}
              </QrCodeContainer>
            </Grid>
          </Grid>
        </StyledPaper>
      </StyledDialogContent>
    </Dialog>
  );
};

export default React.memo(QrcodeModal);
