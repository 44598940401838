import React, { useContext, useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Box,
  useTheme,
  Menu,
  MenuItem,
  Badge,
  useMediaQuery,
  Divider,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

// Icons
import MenuIcon from '@mui/icons-material/Menu';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import AccountCircle from '@mui/icons-material/AccountCircle';
import CachedIcon from '@mui/icons-material/Cached';
import LanguageIcon from '@mui/icons-material/Language';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import { BrazilFlag, USAFlag, SpainFlag } from '../../components/LanguageFlags';

import { useColorMode } from '../themeContext';
import { AuthContext } from '../../context/Auth/AuthContext';
import { i18n } from '../../translate/i18n';
import NotificationsVolume from '../../components/NotificationsVolume';
import NotificationsPopOver from '../../components/NotificationsPopOver';
import AnnouncementsPopover from '../../components/AnnouncementsPopover';
import ChatPopover from '../../pages/Chat/ChatPopover';
import UserModal from '../../components/UserModal';

const drawerWidth = 240;
const miniDrawerWidth = 57;

const Header = ({ onDrawerToggle, onMobileDrawerToggle, isMinimized }) => {
  const theme = useTheme();
  const colorMode = useColorMode();
  const { user, handleLogout } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [languageAnchorEl, setLanguageAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const greaterThanSm = useMediaQuery(theme.breakpoints.up('sm'));
  const greaterThanMd = useMediaQuery(theme.breakpoints.up('md'));
  const [volume, setVolume] = useState(parseFloat(localStorage.getItem("volume")) || 1);
  const [userModalOpen, setUserModalOpen] = useState(false);

  useEffect(() => {
    localStorage.setItem("volume", volume.toString());
  }, [volume]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleLanguageMenu = (event) => {
    setLanguageAnchorEl(event.currentTarget);
  };

  const handleCloseLanguageMenu = () => {
    setLanguageAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleRefreshPage = () => {
    window.location.reload();
    handleMobileMenuClose();
  };

  const handleOpenUserModal = () => {
    setUserModalOpen(true);
    handleCloseMenu();
    handleMobileMenuClose();
  };

  const handleDrawerToggle = () => {
    if (greaterThanSm) {
      onDrawerToggle();
    } else {
      onMobileDrawerToggle();
    }
  };

  const handleLogoutClick = () => {
    handleLogout();
    handleCloseMenu();
    handleMobileMenuClose();
  };

  const handleChangeLanguage = (language) => {
    localStorage.setItem("i18nextLng", language);
    handleCloseLanguageMenu();
    window.location.reload();
  };

  const languageMenuItems = [
    { code: 'pt', label: 'Português', icon: <BrazilFlag sx={{ width: 24, height: 24 }} /> },
    { code: 'en', label: 'English', icon: <USAFlag sx={{ width: 24, height: 24 }} /> },
    { code: 'es', label: 'Español', icon: <SpainFlag sx={{ width: 24, height: 24 }} /> },
  ];

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(mobileMoreAnchorEl)}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleLanguageMenu}>
        <ListItemIcon>
          <LanguageIcon />
        </ListItemIcon>
        <ListItemText primary={i18n.t("mainDrawer.appBar.language")} />
      </MenuItem>
      
      <MenuItem onClick={colorMode.toggleColorMode}>
        <ListItemIcon>
          {theme.palette.mode === 'dark' ? <Brightness7Icon fontSize="small" /> : <Brightness4Icon fontSize="small" />}
        </ListItemIcon>
        <ListItemText primary={i18n.t("mainDrawer.appBar.theme")} />
      </MenuItem>

      <MenuItem>
        <ListItemIcon>
          <VolumeUpIcon fontSize="small" />
        </ListItemIcon>
        <NotificationsVolume
          setVolume={setVolume}
          volume={volume}
        />
      </MenuItem>

      <MenuItem onClick={handleRefreshPage}>
        <ListItemIcon>
          <CachedIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={i18n.t("mainDrawer.appBar.refresh")} />
      </MenuItem>

      <Divider />

      <MenuItem onClick={handleOpenUserModal}>
        <ListItemIcon>
          <PersonIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={i18n.t("mainDrawer.appBar.user.profile")} />
      </MenuItem>

      <MenuItem onClick={handleLogoutClick}>
        <ListItemIcon>
          <LogoutIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={i18n.t("mainDrawer.appBar.user.logout")} />
      </MenuItem>
    </Menu>
  );

  const shouldShowToggle = () => {
    if (!greaterThanSm) return true; // Mobile
    if (greaterThanMd) return true; // Desktop
    return false; // Tablet (md)
  };

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          background: theme.palette.barraSuperior,
          width: { 
            xs: '100%',
            sm: `calc(100% - ${miniDrawerWidth}px)`,
            md: `calc(100% - ${isMinimized ? miniDrawerWidth : drawerWidth}px)`
          },
          ml: { 
            xs: 0,
            sm: `${miniDrawerWidth}px`,
            md: isMinimized ? `${miniDrawerWidth}px` : `${drawerWidth}px`
          },
          transition: theme => theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
        }}
      >
        <Toolbar>
          {shouldShowToggle() && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
          )}
          
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {greaterThanSm && user?.profile === "admin" && user?.company?.dueDate ? (
              <>
                {i18n.t("mainDrawer.appBar.greeting.hello")} <b>{user.name}</b>, {i18n.t("mainDrawer.appBar.greeting.welcome")} <b>{user?.company?.name}</b>!
              </>
            ) : (
              <>
                {i18n.t("mainDrawer.appBar.greeting.hello")} <b>{user?.name}</b>!
              </>
            )}
          </Typography>

          {/* Desktop Menu */}
          <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center', gap: 1 }}>
            {/* Language Selector */}
            <IconButton
              color="inherit"
              onClick={handleLanguageMenu}
              size="large"
            >
              {languageMenuItems.find(item => item.code === i18n.language)?.icon || <LanguageIcon />}
            </IconButton>
            <Menu
              id="language-menu"
              anchorEl={languageAnchorEl}
              open={Boolean(languageAnchorEl)}
              onClose={handleCloseLanguageMenu}
            >
              {languageMenuItems.map((item) => (
                <MenuItem
                  key={item.code}
                  onClick={() => handleChangeLanguage(item.code)}
                  selected={i18n.language === item.code}
                >
                  <Box sx={{ mr: 2, display: 'flex', alignItems: 'center' }}>
                    {item.icon}
                  </Box>
                  <ListItemText>{item.label}</ListItemText>
                </MenuItem>
              ))}
            </Menu>

            {/* Theme Toggle */}
            <IconButton
              color="inherit"
              onClick={colorMode.toggleColorMode}
            >
              {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
            </IconButton>

            {/* Volume */}
            <NotificationsVolume
              setVolume={setVolume}
              volume={volume}
            />

            {/* Refresh */}
            <IconButton
              color="inherit"
              onClick={handleRefreshPage}
            >
              <CachedIcon />
            </IconButton>

            {/* Notifications */}
            {user?.id && <NotificationsPopOver volume={volume} />}

            {/* Announcements */}
            <AnnouncementsPopover />

            {/* Chat */}
            <ChatPopover />

            {/* User Menu */}
            <IconButton
              color="inherit"
              onClick={handleMenu}
            >
              <AccountCircle />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleCloseMenu}
            >
              <MenuItem onClick={handleOpenUserModal}>
                {i18n.t("mainDrawer.appBar.user.profile")}
              </MenuItem>
              <MenuItem onClick={handleLogoutClick}>
                {i18n.t("mainDrawer.appBar.user.logout")}
              </MenuItem>
            </Menu>
          </Box>

          {/* Mobile Menu */}
          <Box sx={{ display: { xs: 'flex', md: 'none' }, alignItems: 'center', gap: 1 }}>
            {/* Notifications */}
            {user?.id && <NotificationsPopOver volume={volume} />}

            {/* Announcements */}
            <AnnouncementsPopover />

            {/* Chat */}
            <ChatPopover />

            <IconButton
              color="inherit"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
            >
              <MoreVertIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>

      {renderMobileMenu}

      <UserModal
        open={userModalOpen}
        onClose={() => setUserModalOpen(false)}
        userId={user?.id}
      />
    </>
  );
};

export default Header; 