import React, { useState, useEffect, useRef, useContext } from "react";

import { useHistory, useParams } from "react-router-dom";
import { parseISO, format, isSameDay } from "date-fns";
import clsx from "clsx";

import { makeStyles } from "@mui/styles";
import { green, grey, red, blue, orange } from "@mui/material/colors";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import useTheme from "@mui/material/styles/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import MarkdownWrapper from "../MarkdownWrapper";
import { Tooltip } from "@mui/material";
import { AuthContext } from "../../context/Auth/AuthContext";
import { TicketsContext } from "../../context/Tickets/TicketsContext";
import toastError from "../../errors/toastError";
import { v4 as uuidv4 } from "uuid";

import RoomIcon from '@mui/icons-material/Room';
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import AndroidIcon from "@mui/icons-material/Android";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import EventSeatOutlinedIcon from "@mui/icons-material/EventSeatOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import TicketMessagesDialog from "../TicketMessagesDialog";
import DoneIcon from '@mui/icons-material/Done';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import FolderIcon from '@mui/icons-material/Folder';
import contrastColor from "../../helpers/contrastColor";
import ContactTag from "../ContactTag";
import { Icon } from '@iconify/react';

// Adicionando traduções personalizadas
const customButtonLabels = {
  accept: i18n.t("ticketsList.buttons.accept") || "Aceitar",
  resolve: "Resolver",
  reopen: i18n.t("ticketsList.buttons.reopen") || "Reabrir"
};

const useStyles = makeStyles((theme) => ({
  ticket: {
    position: "relative",
    paddingRight: 0,
  },
  pendingTicket: {
    cursor: "unset",
  },
  queueTag: {
    background: "#FCFCFC",
    color: "#000",
    marginRight: 4,
    padding: "2px 4px",
    fontWeight: 'bold',
    borderRadius: 3,
    fontSize: "0.7rem",
    whiteSpace: "nowrap"
  },
  newMessagesCount: {
    position: "absolute",
    bottom: -2,
    right: -2,
    backgroundColor: "#2196F3", 
    color: "white", 
    borderRadius: "50%", 
    width: 20, 
    height: 20, 
    display: "flex", 
    alignItems: "center", 
    justifyContent: "center", 
    fontSize: "0.75rem", 
    fontWeight: 500,
    border: "2px solid #fff",
    zIndex: 1,
  },
  connectionTag: {
    marginRight: 4,
    padding: "2px 4px",
    fontWeight: 'bold',
    borderRadius: 3,
    fontSize: "0.7rem",
    whiteSpace: "nowrap",
    color: "#FFF"
  },
  contactNameWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  lastMessageTime: {
    fontSize: "0.75rem",
    color: "rgba(0, 0, 0, 0.54)",
  },
  contactLastMessage: {
    marginRight: 5,
  },
  badgeStyle: {
    color: "white",
    backgroundColor: green[500],
    height: 16,
    padding: "0 6px",
    fontSize: "0.75rem",
    borderRadius: 8,
  },
  acceptButton: {
    position: "absolute",
    right: 0,
    top: 0,
    bottom: 0,
    width: "70px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#4054B2",
    color: "white",
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    border: "none",
    outline: "none",
    cursor: "pointer",
    transition: "0.2s ease",
    padding: 0,
    "&:hover": {
      backgroundColor: "#3045A3",
    },
    "&:disabled": {
      opacity: 0.7,
      cursor: "not-allowed"
    }
  },
  resolveButton: {
    position: "absolute",
    right: 0,
    top: 0,
    bottom: 0,
    width: "70px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#607D8B",
    color: "white",
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    border: "none",
    outline: "none",
    cursor: "pointer",
    transition: "0.2s ease",
    padding: 0,
    "&:hover": {
      backgroundColor: "#546E7A",
    },
    "&:disabled": {
      opacity: 0.7,
      cursor: "not-allowed"
    }
  },
  reopenButton: {
    position: "absolute",
    right: 0,
    top: 0,
    bottom: 0,
    width: "70px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#FF9800",
    color: "white",
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    border: "none",
    outline: "none",
    cursor: "pointer",
    transition: "0.2s ease",
    padding: 0,
    "&:hover": {
      backgroundColor: "#F57C00",
    },
    "&:disabled": {
      opacity: 0.7,
      cursor: "not-allowed"
    }
  },
  ticketQueueColor: {
    flex: "none",
    width: "10px",
    height: "100%",
    position: "absolute",
    top: "0%",
    left: "0%",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
    transition: "all 0.3s ease",
    zIndex: 1,
    "&:hover": {
      width: "15px",
      boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.3)",
    }
  },
  secondaryContentSecond: {
    display: 'flex',
    alignItems: "center",
    flexWrap: "wrap",
    marginTop: 2,
  },
  iconStyle: {
    fontSize: "1rem",
    marginLeft: 5,
    color: grey[600],
  },
  whatsAppBadge: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 22,
    height: 22,
    borderRadius: "50%",
  },
  viewIconButton: {
    padding: 4,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  buttonIcon: {
    fontSize: "1.5rem",
    marginBottom: "5px",
  },
  buttonText: {
    fontSize: "0.75rem",
    fontWeight: 500,
  },
  listItemContent: {
    paddingRight: 70,
  },
  avatarWrapper: {
    position: "relative"
  },
  tagContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 2,
    marginTop: 1,
  },
  tag: {
    backgroundColor: "#F1F1F1",
    color: "#333",
    padding: "0px 4px",
    borderRadius: 4,
    fontSize: "0.65rem",
    display: "inline-flex",
    alignItems: "center",
    marginRight: 3,
    height: '16px',
  },
}));

const ActionButton = ({ onClick, disabled, type, icon, isDarkMode }) => {
  const classes = useStyles();
  
  let buttonClass, buttonText, buttonStyles;
  
  switch(type) {
    case "accept":
      buttonClass = classes.acceptButton;
      buttonText = customButtonLabels.accept;
      buttonStyles = {
        backgroundColor: isDarkMode ? "#2C387E" : "#4054B2",
        "&:hover": {
          backgroundColor: isDarkMode ? "#1A237E" : "#3045A3",
        }
      };
      break;
    case "resolve":
      buttonClass = classes.resolveButton;
      buttonText = customButtonLabels.resolve;
      buttonStyles = {
        backgroundColor: isDarkMode ? "#455A64" : "#607D8B",
        "&:hover": {
          backgroundColor: isDarkMode ? "#37474F" : "#546E7A",
        }
      };
      break;
    case "reopen":
      buttonClass = classes.reopenButton;
      buttonText = customButtonLabels.reopen;
      buttonStyles = {
        backgroundColor: isDarkMode ? "#E65100" : "#FF9800",
        "&:hover": {
          backgroundColor: isDarkMode ? "#D84315" : "#F57C00",
        }
      };
      break;
    default:
      buttonClass = classes.acceptButton;
      buttonText = customButtonLabels.accept;
      buttonStyles = {
        backgroundColor: isDarkMode ? "#2C387E" : "#4054B2",
        "&:hover": {
          backgroundColor: isDarkMode ? "#1A237E" : "#3045A3",
        }
      };
  }
  
  return (
    <button 
      className={buttonClass}
      onClick={onClick}
      disabled={disabled}
      style={buttonStyles}
    >
      {icon}
      <span className={classes.buttonText}>{buttonText}</span>
    </button>
  );
};

const TicketListItemCustom = ({ ticket }) => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const [loading, setLoading] = useState(false);
  const [ticketUser, setTicketUser] = useState(null);
  const [ticketQueueName, setTicketQueueName] = useState(null);
  const [ticketQueueColor, setTicketQueueColor] = useState(null);
  const [tag, setTag] = useState([]);
  const [whatsAppName, setWhatsAppName] = useState(null);

  const [openTicketMessageDialog, setOpenTicketMessageDialog] = useState(false);
  const { ticketId } = useParams();
  const isMounted = useRef(true);
  const { setCurrentTicket } = useContext(TicketsContext);
  const { user } = useContext(AuthContext);
  const { profile } = user;

  useEffect(() => {
    if (ticket.userId && ticket.user) {
      setTicketUser(ticket.user?.name?.toUpperCase());
    }
    setTicketQueueName(ticket.queue?.name?.toUpperCase());
    setTicketQueueColor(ticket.queue?.color);

    if (ticket.whatsappId && ticket.whatsapp) {
      setWhatsAppName(ticket.whatsapp.name?.toUpperCase());
    }

    setTag(ticket?.tags);

    return () => {
      isMounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseTicket = async (id) => {
    setTag(ticket?.tags);
    setLoading(true);
    try {
      await api.put(`/tickets/${id}`, {
        status: "closed",
        userId: user?.id,
        queueId: ticket?.queue?.id,
        useIntegration: false,
        promptId: null,
        integrationId: null
      });
    } catch (err) {
      setLoading(false);
      toastError(err);
    }
    if (isMounted.current) {
      setLoading(false);
    }
    history.push(`/tickets/`);
  };

  const handleReopenTicket = async (id) => {
    setLoading(true);
    try {
      await api.put(`/tickets/${id}`, {
        status: "open",
        userId: user?.id,
        queueId: ticket?.queue?.id
      });
    } catch (err) {
      setLoading(false);
      toastError(err);
    }
    if (isMounted.current) {
      setLoading(false);
    }
    history.push(`/tickets/${ticket.uuid}`);
  };

  const handleAcepptTicket = async (id) => {
    setLoading(true);
    try {
      await api.put(`/tickets/${id}`, {
        status: "open",
        userId: user?.id,
      });
      
      let settingIndex;

      try {
        const { data } = await api.get("/settings/");
        
        settingIndex = data.filter((s) => s.key === "sendGreetingAccepted");
        
      } catch (err) {
        toastError(err);
          
      }
      
      if (settingIndex[0].value === "enabled" && !ticket.isGroup) {
        handleSendMessage(ticket.id);
        
      }

    } catch (err) {
      setLoading(false);
      
      toastError(err);
    }
    if (isMounted.current) {
      setLoading(false);
    }

    history.push(`/tickets/${ticket.uuid}`);
  };
  
  const handleSendMessage = async (id) => {
    
    const msg = `{{ms}} *{{name}}*, meu nome é *${user?.name}* e agora vou prosseguir com seu atendimento!`;
    const message = {
        read: 1,
        fromMe: true,
        mediaUrl: "",
        body: `*Mensagem Automática:*\n${msg.trim()}`,
    };
    try {
        await api.post(`/messages/${id}`, message);
    } catch (err) {
        toastError(err);
        
    }
  };

  const handleSelectTicket = (ticket) => {
    const code = uuidv4();
    const { id, uuid } = ticket;
    setCurrentTicket({ id, uuid, code });
  };

  return (
    <React.Fragment key={ticket.id}>
      <TicketMessagesDialog
        open={openTicketMessageDialog}
        handleClose={() => setOpenTicketMessageDialog(false)}
        ticketId={ticket.id}
      />
      <ListItem dense button
        onClick={(e) => {
          if (ticket.status === "pending") return;
          handleSelectTicket(ticket);
        }}
        selected={ticketId && +ticketId === ticket.id}
        className={clsx(classes.ticket, {
          [classes.pendingTicket]: ticket.status === "pending",
        })}
        style={{
          backgroundColor: isDarkMode ? 'rgba(255, 255, 255, 0.05)' : 'inherit',
          color: isDarkMode ? '#fff' : 'inherit',
          paddingLeft: '16px'
        }}
      >
        <Tooltip arrow placement="right" title={
          <div style={{ display: 'flex', alignItems: 'center', padding: '5px' }}>
            {/* <FolderIcon fontSize="small" style={{ marginRight: '5px' }} /> */}
            <Icon icon="ri:customer-service-fill" style={{ marginRight: '5px' }} />
            <span>{ticket.queue?.name?.toUpperCase() || i18n.t("ticketsListItem.noQueue")}</span>
          </div>
        }>
          <span 
            style={{ 
              backgroundColor: ticket.queue?.color || "#7C7C7C",
              boxShadow: isDarkMode ? '0px 0px 6px rgba(255, 255, 255, 0.15)' : '0px 0px 5px rgba(0, 0, 0, 0.2)'
            }} 
            className={classes.ticketQueueColor}
          ></span>
        </Tooltip>
        <ListItemAvatar>
          <div className={classes.avatarWrapper}>
            <Avatar
              style={{
                width: "48px",
                height: "48px",
                borderRadius: "8%",
              }}
              src={ticket?.contact?.profilePicUrl}
              alt={ticket.contact.name}
            />
            {ticket.unreadMessages > 0 && (
              <Box 
                className={classes.newMessagesCount}
                style={{ 
                  borderColor: isDarkMode ? 'rgba(30, 30, 30, 0.9)' : '#fff'
                }}
              >
                {ticket.unreadMessages}
              </Box>
            )}
          </div>
        </ListItemAvatar>
        <ListItemText
          disableTypography
          className={classes.listItemContent}
          primary={
            <span className={classes.contactNameWrapper}>
              <Typography
                noWrap
                component="span"
                variant="body2"
                style={{ 
                  fontWeight: 500,
                  color: isDarkMode ? '#fff' : 'inherit' 
                }}
              >
                {ticket.contact.name}
                {tag && tag.length > 0 && (
                  <Box 
                    component="span" 
                    style={{ 
                      display: 'inline-flex',
                      marginLeft: 8,
                      gap: 2,
                    }}
                  >
                    {tag.map((tagItem) => {
                      // Determinar se a cor de fundo é clara ou escura
                      const bgColor = tagItem.color || "#F1F1F1";
                      const isLight = (
                        parseInt(bgColor.slice(1, 3), 16) +
                        parseInt(bgColor.slice(3, 5), 16) +
                        parseInt(bgColor.slice(5, 7), 16)
                      ) > 480; // 382 é um limiar para determinar se a cor é clara ou escura (255*3/2)
                      
                      return (
                        <span
                          key={tagItem.id}
                          className={classes.tag}
                          style={{
                            backgroundColor: bgColor,
                            color: isLight ? "#333333" : "#FFFFFF",
                            textTransform: 'lowercase',
                            fontWeight: 500,
                            fontSize: '0.6rem',
                            display: 'flex',
                            alignItems: 'center'
                          }}
                        >
                          {tagItem.name}
                        </span>
                      );
                    })}
                  </Box>
                )}
              </Typography>
              <Typography
                className={classes.lastMessageTime}
                component="span"
                style={{ 
                  color: isDarkMode ? 'rgba(255, 255, 255, 0.6)' : 'rgba(0, 0, 0, 0.54)' 
                }}
              >
                {isSameDay(parseISO(ticket.updatedAt), new Date()) ? (
                  <>{format(parseISO(ticket.updatedAt), "HH:mm")}</>
                ) : (
                  <>{format(parseISO(ticket.updatedAt), "dd/MM/yyyy")}</>
                )}
              </Typography>
            </span>
          }
          secondary={
            <span className={classes.contactNameWrapper}>
              <Typography
                className={classes.contactLastMessage}
                noWrap
                component="span"
                variant="body2"
                style={{ 
                  maxWidth: "85%",
                  color: isDarkMode ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.6)' 
                }}
              >
                {ticket.lastMessage.includes('data:image/png;base64') ? 
                  <MarkdownWrapper><RoomIcon fontSize="small" style={{ verticalAlign: "middle", marginRight: 3 }} /> Localização</MarkdownWrapper> : 
                  <MarkdownWrapper>{ticket.lastMessage}</MarkdownWrapper>
                }
              </Typography>
              <Box display="flex" alignItems="center">
                {ticket.chatbot && (
                  <Tooltip title={i18n.t("ticketsListItem.tooltip.chatbot")}>
                    <EventSeatOutlinedIcon 
                      fontSize="small" 
                      className={classes.iconStyle} 
                      style={{ color: isDarkMode ? 'rgba(255, 255, 255, 0.7)' : grey[600] }}
                      aria-label="Atendimento automático"
                    />
                  </Tooltip>
                )}
                <Tooltip title={`${ticket?.whatsapp?.name || "Principal"}`}>
                  <Box className={classes.whatsAppBadge}>
                    <WhatsAppIcon 
                      fontSize="small" 
                      style={{ 
                        color: "#25D366", 
                        fontSize: "0.9rem" 
                      }} 
                    />
                  </Box>
                </Tooltip>
                {profile === "admin" && (
                  <Tooltip title={i18n.t("ticketsListItem.tooltip.peek")}>
                    <IconButton 
                      size="small" 
                      onClick={(e) => {
                        e.stopPropagation();
                        setOpenTicketMessageDialog(true);
                      }}
                      className={classes.viewIconButton}
                    >
                      <VisibilityOutlinedIcon 
                        fontSize="small" 
                        className={classes.iconStyle}
                        style={{ 
                          color: isDarkMode ? 'rgba(255, 255, 255, 0.7)' : grey[600],
                          marginLeft: 0,
                          fontSize: "1.1rem"
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            </span>
          }
          secondaryTypographyProps={{
            component: 'div'
          }}
        />
        
        {ticket.status === "pending" ? (
          <ActionButton 
            type="accept"
            onClick={(e) => {
              e.stopPropagation();
              handleAcepptTicket(ticket.id);
            }}
            disabled={loading}
            icon={<CheckCircleOutlineIcon className={classes.buttonIcon} />}
            isDarkMode={isDarkMode}
          />
        ) : (
          ticket.status !== "closed" ? (
            <ActionButton 
              type="resolve"
              onClick={(e) => {
                e.stopPropagation();
                handleCloseTicket(ticket.id);
              }}
              disabled={loading}
              icon={<DoneAllIcon className={classes.buttonIcon} />}
              isDarkMode={isDarkMode}
            />
          ) : (
            <ActionButton 
              type="reopen"
              onClick={(e) => {
                e.stopPropagation();
                handleReopenTicket(ticket.id);
              }}
              disabled={loading}
              icon={<ClearOutlinedIcon className={classes.buttonIcon} />}
              isDarkMode={isDarkMode}
            />
          )
        )}
      </ListItem>
      <Divider variant="inset" component="li" />
    </React.Fragment>
  );
};

export default TicketListItemCustom;