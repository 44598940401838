import React, { createContext, useContext, useState, useMemo, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material';
import { useMediaQuery } from '@mui/material';

export const ColorModeContext = createContext({ toggleColorMode: () => {} });

export const useColorMode = () => {
  return useContext(ColorModeContext);
};

export function ThemeContextProvider({ children }) {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const preferredTheme = window.localStorage.getItem('preferredTheme');
  const [mode, setMode] = useState(preferredTheme ? preferredTheme : prefersDarkMode ? 'dark' : 'light');

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    []
  );

  useEffect(() => {
    window.localStorage.setItem('preferredTheme', mode);
  }, [mode]);

  // Cores principais da empresa
  const primaryGreen = '#007C4F'; // Verde principal
  const lightGreen = '#33D39E'; // Verde claro (não usado como background)
  
  // Paleta de cores expandida para tema claro
  const lightPalette = {
    primary: primaryGreen,
    secondary: '#00A36C', // Verde secundário mais escuro
    accent: '#005A3B', // Verde mais escuro para acentos
    success: '#2E8B57', // Verde mar para sucesso
    warning: '#F9A826', // Laranja amarelado para avisos
    error: '#E63946', // Vermelho para erros
    info: '#457B9D', // Azul esverdeado para informações
    background: '#F8F9FA', // Fundo principal levemente cinza
    paper: '#FFFFFF', // Fundo de componentes
    border: '#E0E0E0', // Bordas sutis
    textPrimary: '#212529', // Texto principal quase preto
    textSecondary: '#495057', // Texto secundário cinza escuro
    textLight: '#6C757D', // Texto terciário cinza médio
    highlight: '#E6F4F1', // Destaque sutil esverdeado
  };
  
  // Paleta de cores para tema escuro
  const darkPalette = {
    primary: '#00A36C', // Verde mais claro para melhor visibilidade
    secondary: '#33D39E', // Verde claro como secundário
    accent: '#00C897', // Verde brilhante para acentos
    success: '#4DAA57', // Verde mais claro para sucesso
    warning: '#FFB74D', // Laranja mais claro para avisos
    error: '#FF5A5A', // Vermelho mais claro para erros
    info: '#64B5F6', // Azul mais claro para informações
    background: '#121212', // Fundo principal escuro
    paper: '#1E1E1E', // Fundo de componentes
    border: '#333333', // Bordas sutis
    textPrimary: '#F8F9FA', // Texto principal quase branco
    textSecondary: '#CED4DA', // Texto secundário cinza claro
    textLight: '#ADB5BD', // Texto terciário cinza médio
    highlight: '#1A3B32', // Destaque sutil esverdeado escuro
  };
  
  // Seleciona a paleta baseada no modo
  const themePalette = mode === 'light' ? lightPalette : darkPalette;

  const theme = useMemo(
    () =>
      createTheme({
        scrollbarStyles: {
          '&::-webkit-scrollbar': {
            width: '8px',
            height: '8px',
          },
          '&::-webkit-scrollbar-thumb': {
            boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
            backgroundColor: themePalette.primary,
          },
        },
        scrollbarStylesSoft: {
          '&::-webkit-scrollbar': {
            width: '8px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: themePalette.border,
          },
        },
        palette: {
          mode,
          // Cores padrão do Material UI
          primary: { main: themePalette.primary },
          secondary: { main: themePalette.secondary },
          error: { main: themePalette.error },
          warning: { main: themePalette.warning },
          info: { main: themePalette.info },
          success: { main: themePalette.success },
          
          // Cores personalizadas
          accent: { main: themePalette.accent },
          textPrimary: themePalette.textPrimary,
          textSecondary: themePalette.textSecondary,
          textLight: themePalette.textLight,
          borderPrimary: themePalette.primary,
          dark: { main: mode === 'light' ? '#333333' : '#F3F3F3' },
          light: { main: mode === 'light' ? '#F3F3F3' : '#333333' },
          highlight: themePalette.highlight,
          
          // Elementos de interface
          tabHeaderBackground: mode === 'light' ? '#E9ECEF' : '#2C2C2C',
          optionsBackground: themePalette.paper,
          options: themePalette.paper,
          fontecor: themePalette.primary,
          fancyBackground: themePalette.background,
          bordabox: themePalette.border,
          newmessagebox: themePalette.highlight,
          inputdigita: themePalette.paper,
          contactdrawer: themePalette.paper,
          announcements: mode === 'light' ? '#E9ECEF' : '#2C2C2C',
          login: themePalette.paper,
          announcementspopover: themePalette.paper,
          chatlist: mode === 'light' ? '#E9ECEF' : '#2C2C2C',
          boxlist: mode === 'light' ? '#E9ECEF' : '#2C2C2C',
          boxchatlist: mode === 'light' ? '#E9ECEF' : '#2C2C2C',
          total: themePalette.paper,
          messageIcons: themePalette.textLight,
          inputBackground: themePalette.paper,
          barraSuperior: mode === 'light' 
            ? `linear-gradient(to right, ${primaryGreen}, ${themePalette.secondary}, ${themePalette.accent})` 
            : `linear-gradient(to right, ${themePalette.primary}, ${themePalette.secondary}, ${themePalette.accent})`,
          boxticket: mode === 'light' ? '#E9ECEF' : '#2C2C2C',
          campaigntab: mode === 'light' ? '#E9ECEF' : '#2C2C2C',
          mediainput: mode === 'light' ? '#E9ECEF' : '#2C2C2C',
          background: {
            default: themePalette.background,
            paper: themePalette.paper,
          },
        },
        typography: {
          fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
          ].join(','),
        },
        components: {
          MuiButton: {
            styleOverrides: {
              root: {
                borderRadius: 8,
                textTransform: 'none',
                fontWeight: 500,
              },
              containedPrimary: {
                '&:hover': {
                  backgroundColor: themePalette.accent,
                },
              },
            },
          },
          MuiCard: {
            styleOverrides: {
              root: {
                borderRadius: 12,
                boxShadow: mode === 'light' 
                  ? '0 2px 8px rgba(0, 0, 0, 0.08)' 
                  : '0 2px 8px rgba(0, 0, 0, 0.3)',
              },
            },
          },
        },
      }),
    [mode, themePalette]
  );

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ColorModeContext.Provider>
  );
} 