import React, { useState, useRef } from "react";

import Popover from "@mui/material/Popover";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import { makeStyles } from "@mui/styles";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeDownIcon from "@mui/icons-material/VolumeDown";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import useSound from 'use-sound';

import { Grid, Slider } from "@mui/material";
import alertSound from "../../assets/sound.mp3";

const useStyles = makeStyles((theme) => ({
    tabContainer: {
        padding: theme.spacing(2),
    },
    popoverPaper: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(0.2),
        width: "auto",
        maxWidth: 300,
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(1),
        [theme.breakpoints.down("sm")]: {
            maxWidth: 250,
        },
    },
    noShadow: {
        boxShadow: "none !important",
    },
    icons: {
        color: "#fff",
    },
    customBadge: {
        backgroundColor: "#f44336",
        color: "#fff",
    },
}));

const NotificationsVolume = ({ volume, setVolume }) => {
    const classes = useStyles();

    const anchorEl = useRef();
    const [isOpen, setIsOpen] = useState(false);

    const handleClick = () => {
        setIsOpen((prevState) => !prevState);
    };

    const handleClickAway = () => {
        setIsOpen(false);
    };

    const handleVolumeChange = (event, value) => {
        setVolume(value);
        localStorage.setItem("volume", value.toString());
    };

    const handleChangeCommitted = (event, value) => {
        // Toca o som de teste com o novo volume
        const audio = new Audio(alertSound);
        audio.volume = value;
        audio.play();
    };

    const getVolumeIcon = () => {
        if (volume === 0) return <VolumeOffIcon />;
        if (volume <= 0.5) return <VolumeDownIcon />;
        return <VolumeUpIcon />;
    };

    return (
        <>
            <IconButton
                color="inherit"
                onClick={handleClick}
                ref={anchorEl}
                aria-label="Open Volume Control"
            >
                {getVolumeIcon()}
            </IconButton>
            <Popover
                disableScrollLock
                open={isOpen}
                anchorEl={anchorEl.current}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                classes={{ paper: classes.popoverPaper }}
                onClose={handleClickAway}
            >
                <List dense className={classes.tabContainer}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item>
                            <VolumeDownIcon />
                        </Grid>
                        <Grid item xs>
                            <Slider
                                value={volume}
                                aria-labelledby="volume-slider"
                                step={0.1}
                                min={0}
                                max={1}
                                onChange={handleVolumeChange}
                                onChangeCommitted={handleChangeCommitted}
                                sx={{ 
                                    color: 'primary.main',
                                    width: '180px'
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <VolumeUpIcon />
                        </Grid>
                    </Grid>
                </List>
            </Popover>
        </>
    );
};

export default NotificationsVolume;
