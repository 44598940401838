import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@mui/styles";
import { green } from "@mui/material/colors";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  DialogActions,
  CircularProgress,
  TextField,
  Switch,
  FormControlLabel,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Divider,
  Paper,
  Tooltip,
  IconButton,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormHelperText,
} from "@mui/material";

import {
  Help as HelpIcon,
  ExpandMore as ExpandMoreIcon,
  Info as InfoIcon,
  Message as MessageIcon,
  Link as LinkIcon,
  Settings as SettingsIcon,
  Close as CloseIcon,
} from "@mui/icons-material";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import QueueSelect from "../QueueSelect";
import ModalHeader from "../ModalHeader";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  sectionTitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    fontWeight: "bold",
  },
  sectionContainer: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },
  btnWrapper: {
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  helpIcon: {
    marginLeft: theme.spacing(1),
    fontSize: "1.2rem",
    color: theme.palette.grey[600],
  },
  fieldWithHelp: {
    display: "flex",
    alignItems: "center",
  },
  // Estilos para os acordeões com design melhorado
  // - Ícones específicos para cada seção
  // - Efeitos de transição suave
  // - Destaque visual para o acordeão ativo
  // - Melhor contraste e legibilidade
  accordionRoot: {
    marginBottom: theme.spacing(1),
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    "&:before": {
      display: "none",
    },
    borderRadius: theme.shape.borderRadius,
    overflow: "hidden",
    transition: "all 0.3s ease",
    "&.Mui-expanded": {
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15)",
      margin: theme.spacing(1, 0),
    },
    "& .MuiAccordionSummary-root": {
      minHeight: "48px",
      "& .MuiTypography-root": {
        color: theme.palette.text.primary,
      },
      "& .MuiSvgIcon-root": {
        color: theme.palette.text.primary,
      },
      "&.Mui-expanded": {
        minHeight: "48px",
        backgroundColor: theme.palette.secondary.main,
        "& .MuiTypography-root": {
          color: theme.palette.secondary.contrastText + " !important",
        },
        "& .MuiSvgIcon-root": {
          color: theme.palette.secondary.contrastText + " !important",
        },
      },
      "&:hover": {
        "& .MuiTypography-root": {
          color: theme.palette.secondary.contrastText + " !important",
        },
        "& .MuiSvgIcon-root": {
          color: theme.palette.secondary.contrastText + " !important",
        },
      },
    },
  },
  accordionSummary: {
    fontWeight: "bold",
    padding: theme.spacing(0, 2),
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    transition: "all 0.3s ease",
    minHeight: "48px !important",
    height: "48px",
    display: "flex",
    alignItems: "center",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText + " !important",
      "& .MuiTypography-root": {
        color: theme.palette.secondary.contrastText + " !important",
      },
      "& .MuiSvgIcon-root": {
        color: theme.palette.secondary.contrastText + " !important",
      },
    },
    "& .MuiAccordionSummary-content": {
      margin: theme.spacing(1, 0),
      display: "flex",
      alignItems: "center",
      "&.Mui-expanded": {
        margin: theme.spacing(1, 0),
      },
    },
    "& .MuiSvgIcon-root": {
      color: theme.palette.text.primary,
    },
    "& .MuiTypography-root": {
      color: theme.palette.text.primary,
      fontWeight: "500",
    },
    "&.Mui-expanded": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText + " !important",
      "& .MuiTypography-root": {
        color: theme.palette.secondary.contrastText + " !important",
      },
      "& .MuiSvgIcon-root": {
        color: theme.palette.secondary.contrastText + " !important",
      },
    },
  },
  accordionSummaryExpanded: {
    minHeight: "48px !important",
    height: "48px !important",
    display: "flex",
    alignItems: "center",
    "& .MuiAccordionSummary-content": {
      margin: theme.spacing(1, 0) + " !important",
      display: "flex",
      alignItems: "center",
    },
    backgroundColor: theme.palette.secondary.main + " !important",
    color: theme.palette.secondary.contrastText + " !important",
    "& .MuiTypography-root": {
      color: theme.palette.secondary.contrastText + " !important",
    },
    "& .MuiSvgIcon-root": {
      color: theme.palette.secondary.contrastText + " !important",
    },
  },
  accordionIcon: {
    marginRight: theme.spacing(1.5),
    color: "inherit",
    fontSize: "1.3rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  accordionDetails: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
    borderTop: `1px solid ${theme.palette.divider}`,
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  accordionContent: {
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  formLabel: {
    fontWeight: 500,
    marginBottom: theme.spacing(1),
    color: theme.palette.text.primary,
  },
  textField: {
    marginTop: theme.spacing(1),
  },
  switchFormControl: {
    marginTop: theme.spacing(1),
    marginLeft: 0,
  },
  sectionHeader: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(1, 2),
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(2),
  },
  fieldGroup: {
    marginBottom: theme.spacing(2),
  },
  tooltipContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(0.5),
  },
  helpIconButton: {
    padding: 2,
    marginLeft: theme.spacing(0.5),
  },
  helpIcon: {
    fontSize: "1rem",
    color: theme.palette.primary.main,
  },
  tooltipLabel: {
    display: "flex",
    alignItems: "center",
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
  tooltipContent: {
    fontSize: "0.875rem",
    padding: theme.spacing(1),
    maxWidth: 300,
  },
  requiredField: {
    color: theme.palette.error.main,
    marginLeft: theme.spacing(0.5),
  },
  accordionTitle: {
    color: "inherit",
    fontWeight: "500",
  },
}));

const SessionSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, i18n.t("whatsappModal.formErrors.name.short"))
    .max(50, i18n.t("whatsappModal.formErrors.name.long"))
    .required(i18n.t("whatsappModal.formErrors.name.required")),
  token: Yup.string(),
  expiresTicket: Yup.number()
    .typeError(i18n.t("whatsappModal.formErrors.expiresTicket.invalid"))
    .min(0, i18n.t("whatsappModal.formErrors.expiresTicket.min")),
  timeToTransfer: Yup.number()
    .typeError(i18n.t("whatsappModal.formErrors.timeToTransfer.invalid"))
    .min(0, i18n.t("whatsappModal.formErrors.timeToTransfer.min")),
});

const WhatsAppModal = ({ open, onClose, whatsAppId }) => {
  const classes = useStyles();
  const initialState = {
    name: "",
    greetingMessage: "",
    complationMessage: "",
    outOfHoursMessage: "",
    ratingMessage: "",
    isDefault: false,
    token: "",
    provider: "beta",
    expiresInactiveMessage: "",
    expiresTicket: 0,
    timeUseBotQueues: 0,
    maxUseBotQueues: 3,
    integration: null,
    timeToTransfer: 0,
  };

  // Componente personalizado para o ícone de expansão
  const CustomExpandIcon = () => (
    <Box 
      display="flex"
      alignItems="center"
      justifyContent="center"
      width={48}
      height={48}
    >
      <ExpandMoreIcon 
        style={{ 
          fontSize: 48,
          width: 48,
          height: 48
        }} 
      />
    </Box>
  );

  const [whatsApp, setWhatsApp] = useState(initialState);
  const [selectedQueueIds, setSelectedQueueIds] = useState([]);
  const [queues, setQueues] = useState([]);
  const [selectedQueueId, setSelectedQueueId] = useState(null);
  const [selectedPrompt, setSelectedPrompt] = useState(null);
  const [prompts, setPrompts] = useState([]);
  const [integrations, setIntegrations] = useState([]);
  const [selectedIntegration, setSelectedIntegration] = useState(null);
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    const fetchSession = async () => {
      if (!whatsAppId) return;

      try {
        setLoading(true);
        const { data } = await api.get(`whatsapp/${whatsAppId}?session=0`);

        setWhatsApp(data);
        setSelectedPrompt(data.promptId);
        setSelectedIntegration(data.integrationId);

        const whatsQueueIds = data.queues?.map((queue) => queue.id);
        setSelectedQueueIds(whatsQueueIds);
        setSelectedQueueId(data.transferQueueId);
      } catch (err) {
        toastError(err);
      } finally {
        setLoading(false);
      }
    };
    fetchSession();
  }, [whatsAppId]);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get("/prompt");
        setPrompts(data.prompts);

        const { data: dataIntegration } = await api.get("/queueIntegration");
        setIntegrations(dataIntegration.queueIntegrations);
      } catch (err) {
        toastError(err);
      }
    })();
  }, [whatsAppId]);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get("/queue");
        setQueues(data);
      } catch (err) {
        toastError(err);
      }
    })();
  }, []);

  const handleSaveWhatsApp = async (values) => {
    const whatsappData = {
      ...values,
      queueIds: selectedQueueIds,
      transferQueueId: selectedQueueId,
      promptId: selectedPrompt ? selectedPrompt : null,
      integrationId: selectedIntegration,
    };
    delete whatsappData["queues"];
    delete whatsappData["session"];

    try {
      if (whatsAppId) {
        await api.put(`/whatsapp/${whatsAppId}`, whatsappData);
      } else {
        await api.post("/whatsapp", whatsappData);
      }
      toast.success(i18n.t("whatsappModal.success"));
      handleClose();
    } catch (err) {
      toastError(err);
    }
  };

  const handleChangeQueue = (e) => {
    setSelectedQueueIds(e);
    setSelectedPrompt(null);
  };

  const handleChangePrompt = (e) => {
    setSelectedPrompt(e.target.value);
    setSelectedQueueIds([]);
  };

  const handleChangeIntegration = (e) => {
    setSelectedIntegration(e.target.value);
  };

  const handleClose = () => {
    onClose();
    setWhatsApp(initialState);
    setSelectedQueueId(null);
    setSelectedQueueIds([]);
  };

  const LabelWithTooltip = ({ label, tooltip, required }) => (
    <div className={classes.tooltipContainer}>
      <Typography variant="subtitle2" className={classes.tooltipLabel}>
        {label}
        {required && <span className={classes.requiredField}>*</span>}
        {tooltip && (
          <Tooltip 
            title={
              <Typography className={classes.tooltipContent}>
                {tooltip}
              </Typography>
            } 
            arrow 
            placement="top"
          >
            <IconButton size="small" className={classes.helpIconButton}>
              <HelpIcon className={classes.helpIcon} />
            </IconButton>
          </Tooltip>
        )}
      </Typography>
    </div>
  );

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        scroll="paper"
      >
        <ModalHeader 
          title={whatsAppId
            ? i18n.t("whatsappModal.title.edit")
            : i18n.t("whatsappModal.title.add")}
          onClose={handleClose}
        />
        <Formik
          initialValues={whatsApp}
          enableReinitialize={true}
          validationSchema={SessionSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveWhatsApp(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ values, touched, errors, isSubmitting }) => (
            <Form>
              <DialogContent dividers>
                {loading ? (
                  <Box display="flex" justifyContent="center" p={2}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <>
                    <Box mb={2} display="flex" alignItems="center">
                      <Typography variant="caption" color="textSecondary">
                        <span className={classes.requiredField}>*</span> {i18n.t("whatsappModal.requiredFields")}
                      </Typography>
                    </Box>
                    {/* Informações Básicas */}
                    <Accordion 
                      defaultExpanded 
                      className={classes.accordionRoot}
                    >
                      <AccordionSummary
                        expandIcon={<CustomExpandIcon />}
                        className={classes.accordionSummary}
                        classes={{
                          expanded: classes.accordionSummaryExpanded,
                        }}
                      >
                        <Box display="flex" alignItems="center" className={classes.accordionContent}>
                          <InfoIcon className={classes.accordionIcon} />
                          <Typography variant="subtitle1" className={classes.accordionTitle}>
                            {i18n.t("whatsappModal.sections.basic")}
                          </Typography>
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails className={classes.accordionDetails}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={6}>
                            <LabelWithTooltip 
                              label={i18n.t("whatsappModal.form.name")} 
                              tooltip={i18n.t("whatsappModal.tooltips.name")}
                              required={true}
                            />
                            <Field
                              as={TextField}
                              name="name"
                              autoFocus
                              error={touched.name && Boolean(errors.name)}
                              helperText={touched.name && errors.name}
                              variant="outlined"
                              size="small"
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <LabelWithTooltip 
                              label={i18n.t("whatsappModal.form.default")}
                              tooltip={i18n.t("whatsappModal.tooltips.default")}
                              required={false}
                            />
                            <FormControlLabel
                              control={
                                <Field
                                  as={Switch}
                                  color="primary"
                                  name="isDefault"
                                  checked={values.isDefault}
                                />
                              }
                              label={i18n.t("whatsappModal.form.default")}
                              className={classes.switchFormControl}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <LabelWithTooltip 
                              label={i18n.t("whatsappModal.form.token")}
                              tooltip={i18n.t("whatsappModal.tooltips.token")}
                              required={false}
                            />
                            <Field
                              as={TextField}
                              type="text"
                              name="token"
                              variant="outlined"
                              size="small"
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>

                    {/* Mensagens */}
                    <Accordion className={classes.accordionRoot}>
                      <AccordionSummary
                        expandIcon={<CustomExpandIcon />}
                        className={classes.accordionSummary}
                        classes={{
                          expanded: classes.accordionSummaryExpanded,
                        }}
                      >
                        <Box display="flex" alignItems="center" className={classes.accordionContent}>
                          <MessageIcon className={classes.accordionIcon} />
                          <Typography variant="subtitle1" className={classes.accordionTitle}>
                            {i18n.t("whatsappModal.sections.messages")}
                          </Typography>
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails className={classes.accordionDetails}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} className={classes.fieldGroup}>
                            <LabelWithTooltip
                              label={i18n.t("whatsappModal.form.greetingMessage")}
                              tooltip={i18n.t("whatsappModal.tooltips.greetingMessage")}
                              required={false}
                            />
                            <Field
                              as={TextField}
                              multiline
                              rows={3}
                              fullWidth
                              name="greetingMessage"
                              error={
                                touched.greetingMessage && Boolean(errors.greetingMessage)
                              }
                              helperText={
                                touched.greetingMessage && errors.greetingMessage
                              }
                              variant="outlined"
                              size="small"
                            />
                          </Grid>
                          <Grid item xs={12} className={classes.fieldGroup}>
                            <LabelWithTooltip
                              label={i18n.t("whatsappModal.form.complationMessage")}
                              tooltip={i18n.t("whatsappModal.tooltips.complationMessage")}
                              required={false}
                            />
                            <Field
                              as={TextField}
                              multiline
                              rows={3}
                              fullWidth
                              name="complationMessage"
                              error={
                                touched.complationMessage &&
                                Boolean(errors.complationMessage)
                              }
                              helperText={
                                touched.complationMessage && errors.complationMessage
                              }
                              variant="outlined"
                              size="small"
                            />
                          </Grid>
                          <Grid item xs={12} className={classes.fieldGroup}>
                            <LabelWithTooltip
                              label={i18n.t("whatsappModal.form.outOfHoursMessage")}
                              tooltip={i18n.t("whatsappModal.tooltips.outOfHoursMessage")}
                              required={false}
                            />
                            <Field
                              as={TextField}
                              multiline
                              rows={3}
                              fullWidth
                              name="outOfHoursMessage"
                              error={
                                touched.outOfHoursMessage &&
                                Boolean(errors.outOfHoursMessage)
                              }
                              helperText={
                                touched.outOfHoursMessage && errors.outOfHoursMessage
                              }
                              variant="outlined"
                              size="small"
                            />
                          </Grid>
                          <Grid item xs={12} className={classes.fieldGroup}>
                            <LabelWithTooltip
                              label={i18n.t("whatsappModal.form.ratingMessage")}
                              tooltip={i18n.t("whatsappModal.tooltips.ratingMessage")}
                              required={false}
                            />
                            <Field
                              as={TextField}
                              multiline
                              rows={3}
                              fullWidth
                              name="ratingMessage"
                              error={
                                touched.ratingMessage && Boolean(errors.ratingMessage)
                              }
                              helperText={touched.ratingMessage && errors.ratingMessage}
                              variant="outlined"
                              size="small"
                            />
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>

                    {/* Integrações */}
                    <Accordion className={classes.accordionRoot}>
                      <AccordionSummary
                        expandIcon={<CustomExpandIcon />}
                        className={classes.accordionSummary}
                        classes={{
                          expanded: classes.accordionSummaryExpanded,
                        }}
                      >
                        <Box display="flex" alignItems="center" className={classes.accordionContent}>
                          <LinkIcon className={classes.accordionIcon} />
                          <Typography variant="subtitle1" className={classes.accordionTitle}>
                            {i18n.t("whatsappModal.sections.integrations")}
                          </Typography>
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails className={classes.accordionDetails}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} className={classes.fieldGroup}>
                            <LabelWithTooltip
                              label={i18n.t("whatsappModal.form.queues")}
                              tooltip={i18n.t("whatsappModal.tooltips.queues")}
                              required={false}
                            />
                            <QueueSelect
                              selectedQueueIds={selectedQueueIds}
                              onChange={(selectedIds) => handleChangeQueue(selectedIds)}
                            />
                          </Grid>
                          <Grid item xs={12} md={6} className={classes.fieldGroup}>
                            <LabelWithTooltip
                              label={i18n.t("whatsappModal.form.prompt")}
                              tooltip={i18n.t("whatsappModal.tooltips.prompt")}
                              required={false}
                            />
                            <FormControl
                              variant="outlined"
                              fullWidth
                              size="small"
                            >
                              <Select
                                name="promptId"
                                value={selectedPrompt || ""}
                                onChange={handleChangePrompt}
                                displayEmpty
                                MenuProps={{
                                  anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                  },
                                  transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                  },
                                  getContentAnchorEl: null,
                                }}
                              >
                                <MenuItem value="">
                                  <em>{i18n.t("whatsappModal.form.selectPrompt")}</em>
                                </MenuItem>
                                {prompts.map((prompt) => (
                                  <MenuItem
                                    key={prompt.id}
                                    value={prompt.id}
                                  >
                                    {prompt.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6} className={classes.fieldGroup}>
                            <LabelWithTooltip
                              label={i18n.t("whatsappModal.form.integration")}
                              tooltip={i18n.t("whatsappModal.tooltips.integration")}
                              required={false}
                            />
                            <FormControl
                              variant="outlined"
                              fullWidth
                              size="small"
                            >
                              <Select
                                name="integrationId"
                                value={selectedIntegration || ""}
                                onChange={handleChangeIntegration}
                                displayEmpty
                                MenuProps={{
                                  anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                  },
                                  transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                  },
                                  getContentAnchorEl: null,
                                }}
                              >
                                <MenuItem value="">
                                  <em>{i18n.t("whatsappModal.form.selectIntegration")}</em>
                                </MenuItem>
                                {integrations.map((integration) => (
                                  <MenuItem
                                    key={integration.id}
                                    value={integration.id}
                                  >
                                    {integration.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>

                    {/* Configurações Avançadas */}
                    <Accordion className={classes.accordionRoot}>
                      <AccordionSummary
                        expandIcon={<CustomExpandIcon />}
                        className={classes.accordionSummary}
                        classes={{
                          expanded: classes.accordionSummaryExpanded,
                        }}
                      >
                        <Box display="flex" alignItems="center" className={classes.accordionContent}>
                          <SettingsIcon className={classes.accordionIcon} />
                          <Typography variant="subtitle1" className={classes.accordionTitle}>
                            {i18n.t("whatsappModal.sections.advanced")}
                          </Typography>
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails className={classes.accordionDetails}>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <Box className={classes.sectionHeader}>
                              <Typography variant="subtitle2">
                                {i18n.t("whatsappModal.form.queueRedirection")}
                              </Typography>
                              <Typography variant="body2" color="textSecondary">
                                {i18n.t("whatsappModal.form.queueRedirectionDesc")}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12} md={6} className={classes.fieldGroup}>
                            <LabelWithTooltip
                              label={i18n.t("whatsappModal.form.timeToTransfer")}
                              tooltip={i18n.t("whatsappModal.tooltips.timeToTransfer")}
                              required={false}
                            />
                            <Field
                              as={TextField}
                              type="number"
                              name="timeToTransfer"
                              error={touched.timeToTransfer && Boolean(errors.timeToTransfer)}
                              helperText={touched.timeToTransfer && errors.timeToTransfer}
                              variant="outlined"
                              size="small"
                              fullWidth
                              InputLabelProps={{ shrink: values.timeToTransfer ? true : false }}
                            />
                          </Grid>
                          <Grid item xs={12} md={6} className={classes.fieldGroup}>
                            <LabelWithTooltip
                              label={i18n.t("whatsappModal.form.queue")}
                              tooltip={i18n.t("whatsappModal.tooltips.queue")}
                              required={false}
                            />
                            <QueueSelect
                              selectedQueueIds={selectedQueueId}
                              onChange={(selectedId) => {
                                setSelectedQueueId(selectedId);
                              }}
                              multiple={false}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                          <Grid item xs={12} md={6} className={classes.fieldGroup}>
                            <LabelWithTooltip
                              label={i18n.t("whatsappModal.form.expiresTicket")}
                              tooltip={i18n.t("whatsappModal.tooltips.expiresTicket")}
                              required={false}
                            />
                            <Field
                              as={TextField}
                              type="number"
                              name="expiresTicket"
                              error={touched.expiresTicket && Boolean(errors.expiresTicket)}
                              helperText={touched.expiresTicket && errors.expiresTicket}
                              variant="outlined"
                              size="small"
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} className={classes.fieldGroup}>
                            <LabelWithTooltip
                              label={i18n.t("whatsappModal.form.expiresInactiveMessage")}
                              tooltip={i18n.t("whatsappModal.tooltips.expiresInactiveMessage")}
                              required={false}
                            />
                            <Field
                              as={TextField}
                              multiline
                              rows={3}
                              name="expiresInactiveMessage"
                              error={touched.expiresInactiveMessage && Boolean(errors.expiresInactiveMessage)}
                              helperText={touched.expiresInactiveMessage && errors.expiresInactiveMessage}
                              variant="outlined"
                              size="small"
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </>
                )}
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t("whatsappModal.buttons.cancel")}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {whatsAppId
                    ? i18n.t("whatsappModal.buttons.okEdit")
                    : i18n.t("whatsappModal.buttons.okAdd")}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default React.memo(WhatsAppModal);
