import React, { useContext, useEffect, useRef, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  Paper,
  Typography,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";

import { AuthContext } from "../../context/Auth/AuthContext";
import { useDate } from "../../hooks/useDate";
import api from "../../services/api";
import { green } from "@mui/material/colors";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    flex: 1,
    overflow: "hidden",
    borderRadius: 12, // Aumentado para bordas mais suaves
    height: "100%",
    borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
    backgroundColor: theme.palette.background.default,
    width: "100%",
  },
  messageList: {
    position: "relative",
    overflowY: "auto",
    height: "100%",
    ...theme.scrollbarStyles,
    backgroundColor: theme.palette.background.default,
    padding: "20px 16px", // Adicionado padding para melhor espaçamento
  },
  inputArea: {
    position: "relative",
    height: "auto",
    backgroundColor: theme.palette.background.paper,
    borderTop: "1px solid rgba(0, 0, 0, 0.08)",
    padding: "16px 20px",
    boxShadow: "0 -4px 6px rgba(0, 0, 0, 0.02)",
  },
  input: {
    padding: "12px 16px",
    borderRadius: 25,
    backgroundColor: theme.palette.grey[50],
    border: `1px solid ${theme.palette.grey[200]}`,
    transition: 'all 0.3s ease',
    '&:focus': {
      backgroundColor: '#fff',
      borderColor: theme.palette.primary.main,
      boxShadow: `0 0 0 2px ${theme.palette.primary.light}25`,
    },
  },
  buttonSend: {
    margin: theme.spacing(0.5),
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light + '20',
    transition: 'all 0.2s ease',
    '&:hover': {
      backgroundColor: theme.palette.primary.light + '40',
      transform: 'scale(1.05)',
    },
    '&:active': {
      transform: 'scale(0.95)',
    },
  },
  boxLeft: {
    padding: "12px 16px",
    margin: "8px 0",
    position: "relative",
    backgroundColor: "white",
    maxWidth: 300,
    borderRadius: 16,
    borderBottomLeftRadius: 4,
    boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
    '& Typography': {
      marginBottom: 4,
    },
  },
  boxRight: {
    padding: "12px 16px",
    margin: "8px 0 8px auto",
    position: "relative",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    textAlign: "right",
    maxWidth: 300,
    borderRadius: 16,
    borderBottomRightRadius: 4,
    boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
    '& Typography': {
      marginBottom: 4,
    },
  },
  messageName: {
    fontWeight: 500,
    marginBottom: 4,
  },
  messageTime: {
    opacity: 0.7,
    fontSize: '0.75rem',
    marginTop: 4,
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  messageText: {
    wordWrap: 'break-word',
  },
  }));

export default function ChatMessages({
  chat,
  messages,
  handleSendMessage,
  handleLoadMore,
  scrollToBottomRef,
  pageInfo,
  loading,
}) {
  const classes = useStyles();
  const { user } = useContext(AuthContext);
  const { datetimeToClient } = useDate();
  const baseRef = useRef();

  const [contentMessage, setContentMessage] = useState("");

  const scrollToBottom = () => {
    if (baseRef.current) {
      baseRef.current.scrollIntoView({});
    }
  };

  const unreadMessages = (chat) => {
    if (chat !== undefined) {
      const currentUser = chat.users.find((u) => u.userId === user.id);
      return currentUser.unreads > 0;
    }
    return 0;
  };

  useEffect(() => {
    if (unreadMessages(chat) > 0) {
      try {
        api.post(`/chats/${chat.id}/read`, { userId: user.id });
      } catch (err) {}
    }
    scrollToBottomRef.current = scrollToBottom;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleScroll = (e) => {
    const { scrollTop } = e.currentTarget;
    if (!pageInfo.hasMore || loading) return;
    if (scrollTop < 600) {
      handleLoadMore();
    }
  };

  return (
    <Paper className={classes.mainContainer}>
      <div onScroll={handleScroll} className={classes.messageList}>
        {Array.isArray(messages) &&
          messages.map((item, key) => {
            if (item.senderId === user.id) {
              return (
                <Box key={key} className={classes.boxRight}>
                  <Typography variant="subtitle2" className={classes.messageName}>
                    {item.sender.name}
                  </Typography>
                  <Typography variant="body1" className={classes.messageText}>
                    {item.message}
                  </Typography>
                  <Typography variant="caption" display="block" className={classes.messageTime}>
                    {datetimeToClient(item.createdAt)}
                  </Typography>
                </Box>
              );
            } else {
              return (
                <Box key={key} className={classes.boxLeft}>
                  <Typography variant="subtitle2" className={classes.messageName}>
                    {item.sender.name}
                  </Typography>
                  <Typography variant="body1" className={classes.messageText}>
                    {item.message}
                  </Typography>
                  <Typography variant="caption" display="block" className={classes.messageTime}>
                    {datetimeToClient(item.createdAt)}
                  </Typography>
                </Box>
              );
            }
          })}
        <div ref={baseRef}></div>
      </div>
      <div className={classes.inputArea}>
        <FormControl variant="outlined" fullWidth>
          <Input
            multiline
            value={contentMessage}
            onKeyUp={(e) => {
              if (e.key === "Enter" && contentMessage.trim() !== "") {
                handleSendMessage(contentMessage);
                setContentMessage("");
              }
            }}
            onChange={(e) => setContentMessage(e.target.value)}
            className={classes.input}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    if (contentMessage.trim() !== "") {
                      handleSendMessage(contentMessage);
                      setContentMessage("");
                    }
                  }}
                  className={classes.buttonSend}
                >
                  <SendIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </div>
    </Paper>
  );
}
