import React, { useContext, useState, useEffect } from "react";

import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";

import CallIcon from "@mui/icons-material/Call";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import TimerIcon from '@mui/icons-material/Timer';

import { makeStyles } from "@mui/styles";
import { toast } from "react-toastify";

import ButtonWithSpinner from "../../components/ButtonWithSpinner";
import TableAttendantsStatus from "../../components/Dashboard/TableAttendantsStatus";
import { isArray } from "lodash";
import useDashboard from "../../hooks/useDashboard";
import useContacts from "../../hooks/useContacts";
import { ChatsUser } from "./ChartsUser";
import { isEmpty } from "lodash";
import moment from "moment";
import { ChartsDate } from "./ChartsDate";
import { i18n } from "../../translate/i18n";
import { Icon } from "@iconify/react/dist/iconify.js";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  fixedHeightPaper: {
    padding: theme.spacing(3),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: "auto",
    marginTop: theme.spacing(2),
  },
  customFixedHeightPaper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: 120,
  },
  customFixedHeightPaperLg: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: "100%",
  },
  statusCard: {
    position: "relative",
    padding: theme.spacing(3),
    borderRadius: theme.shape.borderRadius * 2,
    transition: "all 0.3s ease-in-out",
    "&:hover": {
      transform: "translateY(-5px)",
      boxShadow: theme.shadows[4],
    },
  },
  cardContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    position: "relative",
    zIndex: 1,
  },
  cardInfo: {
    flex: 1,
  },
  cardIcon: {
    width: "4rem",
    height: "4rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    color: "#fff",
    marginLeft: theme.spacing(2),
  },
  cardLabel: {
    fontSize: "0.875rem",
    color: "rgba(255, 255, 255, 0.8)",
    marginBottom: theme.spacing(1),
  },
  cardValue: {
    fontSize: "2.5rem !important",
    fontWeight: 800,
    color: "#fff",
    lineHeight: 1.2,
    display: "block",
    textAlign: "left",
    margin: "8px 0",
  },
  blurEffect: {
    position: "absolute",
    right: "-1.5rem",
    bottom: "-1.5rem",
    width: "6rem",
    height: "6rem",
    background: "rgba(255, 255, 255, 0.1)",
    borderRadius: "50%",
    filter: "blur(1rem)",
  },
  alignRight: {
    textAlign: "right",
  },
  selectContainer: {
    width: "100%",
    textAlign: "left",
  },
  fullWidth: {
    width: "100%",
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [counters, setCounters] = useState({});
  const [attendants, setAttendants] = useState([]);
  const [period, setPeriod] = useState(0);
  const [filterType, setFilterType] = useState(1);
  const [dateFrom, setDateFrom] = useState(moment("1", "D").format("YYYY-MM-DD"));
  const [dateTo, setDateTo] = useState(moment().format("YYYY-MM-DD"));
  const [loading, setLoading] = useState(false);
  const { find } = useDashboard();

  useEffect(() => {
    async function firstLoad() {
      await fetchData();
    }
    setTimeout(() => {
      firstLoad();
    }, 1000);
  }, []);

  async function handleChangePeriod(value) {
    setPeriod(value);
  }

  async function handleChangeFilterType(value) {
    setFilterType(value);
    if (value === 1) {
      setPeriod(0);
    } else {
      setDateFrom("");
      setDateTo("");
    }
  }

  async function fetchData() {
    setLoading(true);

    let params = {};

    if (period > 0) {
      params = {
        days: period,
      };
    }

    if (!isEmpty(dateFrom) && moment(dateFrom).isValid()) {
      params = {
        ...params,
        date_from: moment(dateFrom).format("YYYY-MM-DD"),
      };
    }

    if (!isEmpty(dateTo) && moment(dateTo).isValid()) {
      params = {
        ...params,
        date_to: moment(dateTo).format("YYYY-MM-DD"),
      };
    }

    if (Object.keys(params).length === 0) {
      toast.error(i18n.t("dashboard.toasts.selectFilterError"));
      setLoading(false);
      return;
    }

    const data = await find(params);

    setCounters(data.counters);
    if (isArray(data.attendants)) {
      setAttendants(data.attendants);
    } else {
      setAttendants([]);
    }

    setLoading(false);
  }

  function formatTime(minutes) {
    return moment()
      .startOf("day")
      .add(minutes, "minutes")
      .format("HH[h] mm[m]");
  }

  const GetContacts = (all) => {
    let props = {};
    if (all) {
      props = {};
    }
    const { count } = useContacts(props);
    return count;
  };

  function renderFilters() {
    if (filterType === 1) {
      return (
        <>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label={i18n.t("dashboard.filters.initialDate")}
              type="date"
              value={dateFrom}
              onChange={(e) => setDateFrom(e.target.value)}
              className={classes.fullWidth}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label={i18n.t("dashboard.filters.finalDate")}
              type="date"
              value={dateTo}
              onChange={(e) => setDateTo(e.target.value)}
              className={classes.fullWidth}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </>
      );
    } else {
      return (
        <Grid item xs={12} sm={6} md={6}>
          <FormControl className={classes.selectContainer} variant="outlined">
            <InputLabel id="period-selector-label" shrink={true}>
              {i18n.t("dashboard.periodSelect.title")}
            </InputLabel>
            <Select
              labelId="period-selector-label"
              id="period-selector"
              value={period}
              onChange={(e) => handleChangePeriod(e.target.value)}
              label={i18n.t("dashboard.periodSelect.title")}
            >
              <MenuItem value={0}>{i18n.t("dashboard.periodSelect.options.none")}</MenuItem>
              <MenuItem value={3}>{i18n.t("dashboard.periodSelect.options.last3")}</MenuItem>
              <MenuItem value={7}>{i18n.t("dashboard.periodSelect.options.last7")}</MenuItem>
              <MenuItem value={15}>{i18n.t("dashboard.periodSelect.options.last15")}</MenuItem>
              <MenuItem value={30}>{i18n.t("dashboard.periodSelect.options.last30")}</MenuItem>
              <MenuItem value={60}>{i18n.t("dashboard.periodSelect.options.last60")}</MenuItem>
              <MenuItem value={90}>{i18n.t("dashboard.periodSelect.options.last90")}</MenuItem>
            </Select>
            <FormHelperText>{i18n.t("dashboard.periodSelect.helper")}</FormHelperText>
          </FormControl>
        </Grid>
      );
    }
  }

  const StatusCard = ({ icon: Icon, title, value, gradient }) => (
    <Paper 
      className={classes.statusCard}
      style={{ 
        background: `linear-gradient(135deg, ${gradient})`,
      }}
    >
      <div className={classes.cardContent}>
        <div className={classes.cardInfo}>
          <Typography className={classes.cardLabel}>
            {title}
          </Typography>
          <Typography className={classes.cardValue}>
            {value}
          </Typography>
        </div>
        <Box className={classes.cardIcon}>
          <Icon style={{ fontSize: "2.5rem" }} />
        </Box>
      </div>
      <div className={classes.blurEffect} />
    </Paper>
  );

  return (
    <div>
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <StatusCard
              icon={CallIcon}
              title={i18n.t("dashboard.counters.inTalk")}
              value={counters.supportHappening}
              gradient="#2196F3, #1976D2"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <StatusCard
              icon={HourglassEmptyIcon}
              title={i18n.t("dashboard.counters.waiting")}
              value={counters.supportPending}
              gradient="#9C27B0, #7B1FA2"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <StatusCard
              icon={CheckCircleIcon}
              title={i18n.t("dashboard.counters.finished")}
              value={counters.supportFinished}
              gradient="#4CAF50, #388E3C"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <StatusCard
              icon={GroupAddIcon}
              title={i18n.t("dashboard.counters.newContacts")}
              value={GetContacts(true)}
              gradient="#3F51B5, #303F9F"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <StatusCard
              icon={AccessAlarmIcon}
              title={i18n.t("dashboard.counters.averageTalkTime")}
              value={formatTime(counters.avgSupportTime)}
              gradient="#E91E63, #C2185B"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <StatusCard
              icon={TimerIcon}
              title={i18n.t("dashboard.counters.averageWaitTime")}
              value={formatTime(counters.avgWaitTime)}
              gradient="#FF9800, #F57C00"
            />
          </Grid>

          {/* start filter section */}
          <Grid item xs={12}>
            <Grid container spacing={3} sx={{ marginTop: 2 }}>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl className={classes.selectContainer} variant="outlined">
                  <InputLabel id="filter-type-label" shrink={true}>
                    {i18n.t("dashboard.filters.filterType.title")}
                  </InputLabel>
                  <Select
                    labelId="filter-type-label"
                    value={filterType}
                    onChange={(e) => handleChangeFilterType(e.target.value)}
                    label={i18n.t("dashboard.filters.filterType.title")}
                  >
                    <MenuItem value={1}>{i18n.t("dashboard.filters.filterType.options.perDate")}</MenuItem>
                    <MenuItem value={2}>{i18n.t("dashboard.filters.filterType.options.perPeriod")}</MenuItem>
                  </Select>
                  <FormHelperText>
                    {i18n.t("dashboard.filters.filterType.helper")}
                  </FormHelperText>
                </FormControl>
              </Grid>

              {renderFilters()}

              <Grid item xs={12} sm={6} md={2}>
                <Box display="flex" alignItems="flex-start" height="100%">
                  <ButtonWithSpinner
                    className="buttonHover"
                    loading={loading}
                    onClick={() => fetchData()}
                    variant="contained"
                    startIcon={<Icon icon="mdi:filter" />}
                    fullWidth
                    sx={{ 
                      height: { sm:'56px' }
                   }}
                  >
                    {i18n.t("dashboard.buttons.filter")}
                  </ButtonWithSpinner>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          {/* end filter section */}
          <Grid item xs={12}>
            {attendants.length ? (
              <TableAttendantsStatus
                attendants={attendants}
                loading={loading}
              />
            ) : null}
          </Grid>

          <Grid item xs={12}>
            <Paper className={classes.fixedHeightPaper}>
              <ChatsUser />
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper className={classes.fixedHeightPaper}>
              <ChartsDate />
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Dashboard;
