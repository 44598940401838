import React, { useContext } from "react";
import { Route as RouterRoute, Redirect } from "react-router-dom";

import { AuthContext } from "../context/Auth/AuthContext";

const Route = ({ component: Component, isPrivate = false, ...rest }) => {
	const { isAuth, loading, user } = useContext(AuthContext);

	if (loading) {
		return null;
	}

	if (!isAuth && isPrivate) {
		return <Redirect to={{ pathname: "/login", state: { from: rest.location } }} />;
	}

	if (isAuth && !isPrivate) {
		return <Redirect to={{ pathname: "/", state: { from: rest.location } }} />;
	}

	// Redireciona para tickets se não for admin na rota principal
	if (isAuth && rest.path === "/" && user?.profile !== "admin") {
		return <Redirect to={{ pathname: "/tickets", state: { from: rest.location } }} />;
	}

	return <RouterRoute {...rest} component={Component} />;
};

export default Route;
